import React, { SVGProps } from "react";

function LocasafeLogo(props: SVGProps<any>) {
  return (
      <svg
          width="397"
          height="55"
          viewBox="0 0 216.664 74.527"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          {...props}
      >
        <g xmlns="http://www.w3.org/2000/svg">

          <path fill="none" stroke="#F5A15A" strokeWidth="1.727" strokeLinecap="round" strokeLinejoin="round"
                strokeMiterlimit="10"
                d="   M21.46,54.562c1.259,1.319,3.137,3.155,5.614,5.117c3.552,2.813,5.37,4.221,7.454,4.262c2.297,0.045,4.156-1.398,7.874-4.282   c2.486-1.929,4.372-3.758,5.654-5.1"/>

          <path fill="none" stroke="#F5A15A" strokeWidth="1.727" strokeLinecap="round" strokeLinejoin="round"
                strokeMiterlimit="10"
                d="   M57.625,37.703c0.595-2.002,1.018-3.69,1.305-4.913c0,0,1.102-4.708,0.87-11.693c-0.007-0.203-0.027-0.648-0.311-1.058   c-0.686-0.99-2.336-0.937-2.796-0.932c-3.781,0.041-9.653-2.245-10.439-2.55c-1.35-0.526-6.913-2.852-9.382-5.038   c-0.239-0.212-1.005-0.914-2.051-0.933c-1.078-0.02-1.892,0.695-2.174,0.933c-2.42,2.04-7.854,4.487-10.626,5.411   c-3.134,1.045-6.41,2.137-9.196,2.114c-0.418-0.003-1.811-0.039-2.671,0.871c-0.615,0.65-0.696,1.506-0.73,1.961   c-0.36,4.853,1.026,10.628,1.026,10.628c0.294,1.229,0.712,2.902,1.257,4.888"/>
          <path fill="#14438A"
                d="M54.843,31.1c-0.183-0.928-0.942-1.44-1.13-1.567c-0.209-0.14-0.574-0.347-1.091-0.448   c-1.17,0.016-2.34,0.032-3.51,0.048c-0.355-0.86-0.905-1.955-1.763-3.097c-1.021-1.358-2.111-2.294-2.942-2.901L24.71,23.284   c-0.772,0.668-1.724,1.625-2.619,2.922c-0.794,1.151-1.317,2.242-1.664,3.123l-3.853,0.029c-0.407,0.243-0.705,0.497-0.906,0.691   c-0.27,0.261-0.691,0.676-0.963,1.355c-0.146,0.364-0.22,0.547-0.182,0.685c0.117,0.427,1.27,0.728,5.513,0.31   c-1.549,1.616-3.097,3.231-4.644,4.845c0.037,5.046,0.074,10.091,0.111,15.136l5.843-0.044l-0.013-1.702l27.089-0.205l0.014,1.916   l6.128-0.046l-0.116-15.271c-1.599-1.576-3.197-3.152-4.795-4.728c0.668,0.044,4.957,0.308,5.224-0.724   C54.911,31.447,54.881,31.294,54.843,31.1 M25.349,25.705l18.581-0.141c1.153,1.981,2.306,3.963,3.459,5.944l-25.476,0.193   C23.058,29.703,24.204,27.704,25.349,25.705 M20.374,43.406c-1.373,0.01-2.493-1.095-2.504-2.469   c-0.01-1.374,1.093-2.496,2.466-2.507c1.373-0.01,2.494,1.095,2.505,2.469C22.851,42.274,21.747,43.396,20.374,43.406    M43.634,44.83l-17.778,0.135l-0.541-3.106l18.812-0.142L43.634,44.83z M51.767,40.695c0.01,1.374-1.094,2.496-2.466,2.507   c-1.373,0.01-2.494-1.095-2.505-2.469c-0.01-1.374,1.094-2.496,2.467-2.507C50.636,38.215,51.757,39.321,51.767,40.695"/>
          <polygon fill="#14438A"
                   points="83.963,49.938 83.963,53.317 72.49,53.317 72.49,31.04 76.129,31.04 76.129,49.938  "/>
          <path fill="#14438A"
                d="M85.04,45.891c0-4.344,3.379-7.723,7.982-7.723c4.604,0,7.983,3.379,7.983,7.723   c0,4.344-3.379,7.723-7.983,7.723C88.419,53.614,85.04,50.235,85.04,45.891 M97.478,45.891c0-2.487-1.968-4.455-4.456-4.455   c-2.524,0-4.455,1.968-4.455,4.455c0,2.488,1.931,4.456,4.455,4.456C95.51,50.347,97.478,48.379,97.478,45.891"/>
          <path fill="#14438A"
                d="M103.827,45.891c0-4.344,3.379-7.723,7.982-7.723c3.936,0,6.906,2.414,7.723,5.829h-3.676   c-0.705-1.522-2.227-2.561-4.047-2.561c-2.487,0-4.455,1.93-4.455,4.455c0,2.525,1.968,4.456,4.455,4.456   c1.82,0,3.342-1.04,4.047-2.562h3.676c-0.817,3.416-3.787,5.829-7.723,5.829C107.206,53.614,103.827,50.235,103.827,45.891"/>
          <path fill="#14438A"
                d="M139.841,50.421v2.896c-0.334,0.185-0.743,0.297-1.189,0.297c-1.819,0-3.267-1.003-4.01-2.488   c-1.262,1.522-3.155,2.488-5.309,2.488c-4.195,0-7.537-3.416-7.537-7.723c0-4.307,3.342-7.723,7.537-7.723   c1.894,0,3.527,0.706,4.789,1.857v-1.559h3.528V48.75c0,1.3,0.668,1.671,1.374,1.671H139.841z M134.234,45.891   c0-2.487-2.005-4.455-4.456-4.455c-2.487,0-4.455,1.968-4.455,4.455c0,2.488,1.968,4.456,4.455,4.456   C132.229,50.347,134.234,48.379,134.234,45.891"/>
          <path fill="#14438A"
                d="M140.842,48.491l4.641-2.896c0.78,1.671,2.339,2.71,4.27,2.71c1.448,0,2.191-0.631,2.191-1.522   c0-3.379-10.137-1.522-10.137-9.245c0-4.752,3.899-6.794,7.426-6.794c2.785,0,5.421,1.262,7.166,3.304l-3.713,3.601   c-1.188-1.262-2.413-1.93-3.527-1.93c-0.892,0-1.633,0.445-1.633,1.411c0,3.378,10.098,1.262,10.098,9.727   c0,4.27-3.676,6.758-7.946,6.758C145.594,53.615,142.327,51.313,140.842,48.491"/>
          <path fill="#14438A"
                d="M177.784,48.862v4.456c-0.334,0.148-0.78,0.297-1.263,0.297c-2.116,0-3.898-1.003-4.975-2.562   c-1.077,1.596-2.747,2.562-4.901,2.562c-4.047,0-7.202-3.379-7.202-7.723c0-4.344,3.155-7.723,7.202-7.723   c1.56,0,2.859,0.52,3.862,1.374v-1.076h5.495v9.096c0,1.188,0.668,1.299,1.262,1.299H177.784z M170.581,47.08l0.148-0.445   c0.075-0.223,0.112-0.483,0.112-0.743c0-1.671-1.337-2.933-2.934-2.933c-1.67,0-2.969,1.262-2.969,2.933   c0,1.671,1.299,2.933,2.969,2.933C169.096,48.825,170.135,48.12,170.581,47.08"/>
          <path fill="#14438A"
                d="M189.999,42.55h-3.379v10.767h-5.532V42.55h-2.487v-4.084h2.487V34.53c0-3.416,2.896-6.052,6.311-6.052   c1.115,0,2.154,0.223,2.526,0.483v4.27h-1.82c-0.928,0-1.485,0.742-1.485,1.93v3.305h3.379V42.55z"/>
          <path fill="#14438A"
                d="M207.3,45.854v0.928h-10.842c0.149,1.931,0.929,2.971,2.897,2.971c1.411,0,2.264-0.557,2.524-1.523h5.235   c-0.668,3.23-3.379,5.384-7.723,5.384c-5.16,0-8.464-2.97-8.464-7.723c0-4.789,3.341-7.759,8.316-7.759   C204.181,38.132,207.337,41.102,207.3,45.854 M196.756,43.738h5.012c-0.334-1.003-1.076-1.559-2.413-1.559   C197.907,42.179,197.127,42.735,196.756,43.738"/>
        </g>
      </svg>
  );
}

export default LocasafeLogo;
