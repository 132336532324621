import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { CompanyDashBordStats } from "../../Components/Features/PlatformComponents/CompaniesManagement/CompanyDashboard";

export function CompaniesManagementPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("companies_management")}>
        <div className="h-fit  w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl ">
          <CompanyDashBordStats />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
