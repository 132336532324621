import { ContractDrivers } from "../../Types";
import { SelectedDriverDescription } from "../Features/CompanyComponents/RentManagement/RentContractManagement/SelectedDriverDescription";
import React from "react";

export const ExpandedRowDriver = ({
  showContent = true,
  drivers,
  showFiles = true,
}: {
  drivers: ContractDrivers[];
  showContent?: boolean;
  showFiles?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4 items-center w-full">
      {drivers?.map((selectedDriver, index) => {
        return (
          <SelectedDriverDescription
            selectedDriver={selectedDriver.driver}
            isFirst={index === 0}
            key={index}
            showFiles={showFiles}
            showContent={showContent}
          />
        );
      })}
    </div>
  );
};
