import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { AddPlatformBillContent } from "../../Components/Features/PlatformComponents/BillsManagement/AddPlatformBill/AddPlatformBillContent";

export function AddBillPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_bill")}>
        <div className="h-fit  w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl ">
          <AddPlatformBillContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
