import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authRecucer";

const rootReducer = combineReducers({
  authentication: authReducer,
});

export interface RootReducer {
  authentication: AuthState;
}
export default rootReducer;
