import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormikContext } from "formik";
import { FormValues } from "./index";
import { Button, Card, Form, Input, List, QRCode } from "antd";
import { useTranslation } from "react-i18next";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

function AssignUserToCompany({ setCurrentStepper }: propsT) {
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const createUserHandler = async () => {
    try {
      setBtnIsLoading(true);
      const addedUser = await Axios.post("/users", {
        ...values.user,
        companyID: values.addedCompany?.companyID,
      }).then((res) => res.data.message);

      await setFieldValue("addedUser", addedUser).then((_) => {
        setCurrentStepper((prev) => prev + 1);
      });
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setBtnIsLoading(false);
    }
  };

  const goBackHandler = () => {
    setCurrentStepper((prev) => prev - 1);
  };
  return (
    <div className="w-full h-fit flex flex-col gap-2 md:gap-4 items-center">
      <List
        bordered
        size="default"
        rootClassName="w-full md:w-2/4"
        header={t("company_informations")}
      >
        <List.Item>
          <List.Item.Meta
            avatar={
              <QRCode
                value={values.addedCompany?.companyID || "-"}
                size={80}
                bordered={false}
              />
            }
            title={
              <span>
                {t("matricule_fiscal")}: {values.addedCompany?.VAT}
              </span>
            }
            description={
              <div className="w-full flex flex-col gap-2">
                <span>
                  {t("nomCommercialFr")}: {values.addedCompany?.rsLatin}
                </span>
                {values.addedCompany?.rsArabic && (
                  <span>
                    {t("nomCommercialAr")}: {values.addedCompany?.rsArabic}
                  </span>
                )}
                {values.addedCompany?.rsLatin && (
                  <span>
                    {t("typeRegistre")}:{" "}
                    {values.addedCompany?.companyType === "PP"
                      ? t("pp")
                      : t("pm")}
                  </span>
                )}
              </div>
            }
          />
        </List.Item>
      </List>

      <Card title={t("user_informations")} rootClassName="w-full md:w-2/4">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          disabled={false}
          initialValues={values.user}
          rootClassName="w-full  "
          onValuesChange={(fieldKeyValue) => {
            const fieldKey = Object.keys(fieldKeyValue)[0];
            const fieldValue = fieldKeyValue[fieldKey];
            setFieldValue(`user.${fieldKey}`, fieldValue);
          }}
        >
          <Form.Item label={t("name")} name="name">
            <Input value={values.user.name || ""} />
          </Form.Item>
          <Form.Item label={t("lastName")} name="lastName">
            <Input value={values.user.lastName || ""} />
          </Form.Item>
          <Form.Item label={t("auth_email")} name="email">
            <Input value={values.user.email || ""} />
          </Form.Item>
          <Form.Item label={t("phone")} name="phone">
            <Input value={values.user.phone || ""} />
          </Form.Item>
        </Form>
      </Card>

      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="default"
          className="!h-9  !text-xs !px-12   "
          onClick={goBackHandler}
        >
          {t("back").toUpperCase()}
        </Button>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          loading={isBtnLoading}
          onClick={createUserHandler}
        >
          {t("create_user").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default AssignUserToCompany;
