import { ColumnType } from "antd/lib/table";
import { Clients } from "../../../../../Types";
import moment from "moment";
import { TableColumnGroupType } from "antd";

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

export const ClientsListingColumns = (t) => [
  {
    title: t("categorie"),
    dataIndex: "clientType",
    key: "clientType",
    render: (value) => t(value.toLowerCase()),
  },
  {
    title: t("nomCommercialFr"),
    dataIndex: "denomination",
    key: "denomination",
    render: (value) =>
      value || (
        <span className="w-full flex justify-center text-orange-700 font-bold">
          -
        </span>
      ),
  },

  {
    title: t("matricule_fiscal"),
    dataIndex: "vatNumber",
    key: "vatNumber",
    render: (value) =>
      value || (
        <span className="w-full flex justify-center text-orange-700 font-bold">
          -
        </span>
      ),
  },
  {
    title: t("fullName"),
    dataIndex: "fullName",
    key: "fullName",
    render: (value) =>
      value || (
        <span className="w-full flex justify-center text-orange-700 font-bold">
          -
        </span>
      ),
  },

  { title: t("auth_email"), dataIndex: "email", key: "email" },
  { title: t("phone"), dataIndex: "phone", key: "phone" },
  {
    title: t("client_since"),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => moment(value).from(new Date()),
  },
];

export const ClientsExpendableListingColumns: (
  t: any,
) => TableColumnGroupType<Clients> | ColumnType<Clients> = (t) => {
  return [
    {
      title: t("idType"),
      dataIndex: "idType",
      key: "idType",
      render: (value) => t(value),
    },
    {
      title: t("idNumber"),
      dataIndex: "idNumber",
      key: "idNumber",
      render: (value) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("deliveredDate"),
      dataIndex: "idDeliveredDate",
      key: "idDeliveredDate",
      render: (value) =>
        (value && moment(value).format("DD/MM/YYYY")) || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("dateOfBirth"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (value) =>
        (value && moment(value).format("DD/MM/YYYY")) || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("address"),
      dataIndex: "address",
      key: "address",
      render: (value) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("city"),
      dataIndex: "city",
      key: "city",
      render: (value) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("nb_RentContract"),
      dataIndex: "RentContract",
      key: "RentContract",
      render: (value) => (
        <span className="w-full flex justify-center text-orange-700 font-bold">
          {value && value.length}
        </span>
      ),
    },
    {
      title: t("bills"),
      dataIndex: "Bills",
      key: "Bills",
      render: (value) => (
        <span className="w-full flex justify-center text-orange-700 font-bold">
          {value && value.length}
        </span>
      ),
    },
  ];
};
