import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GradingIcon from "@mui/icons-material/Grading";
import { Button, Divider, Result, Spin, Steps } from "antd";
import { Formik } from "formik";
import SelectCarToRent from "./SelectCarToRent";
import SelectDriversToRent from "./SelectDriversToRent";
import ContractInformations from "./ContractInformations";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { RentContracts } from "../../../../../Types";
import { RecapRentContract } from "./RecapRentContract";
import { Link } from "react-router-dom";
import { useCompaniesStatsData } from "../../../../../Hooks/useCompaniesStatsData";

export interface RentInitialValues {
  addedContract?: RentContracts;
  contract: {
    clientID: null | string;
    startDate: null | string | Date;
    endDate: null | string | Date;
    dayPrice: null | number;
    kmCount: null | number;
    FuelCount: null | number;
    comment: null | string;
    galleryBefore: [] | string[];
    galleryAfter: [] | string[];
    isRentWithDriver: boolean;
  };
  drivers:
    | []
    | {
        DriverID: string;
        driverType: "FIRST" | "SECOND";
      };
  fleetID: null | string;
}

export function RentContractContent() {
  const { t } = useTranslation();
  const { companiesStats, isLoading } = useCompaniesStatsData();

  const [currentStepper, setCurrentStepper] = useState<number>(0);

  const stepperItems = [
    {
      title: t("select_car"),
      icon: <DirectionsCarFilledIcon />,
    },
    {
      title: t("select_drivers"),
      icon: <PersonSearchIcon />,
    },
    {
      title: t("contract_informations"),
      icon: <ListAltIcon />,
    },
    {
      title: t("recap"),
      icon: <GradingIcon />,
    },
  ];

  const initialValues: RentInitialValues = {
    contract: {
      clientID: null,
      startDate: null,
      endDate: null,
      dayPrice: null,
      kmCount: null,
      FuelCount: null,
      comment: null,
      galleryBefore: [],
      galleryAfter: [],
      isRentWithDriver: false,
    },
    drivers: [],
    fleetID: null,
  };

  if (isLoading) {
    return (
      <div className="p-10 flex flex-col min-h-fit justify-center items-center w-full">
        <Spin />
      </div>
    );
  }

  if (companiesStats?.point === 0) {
    return (
      <div className="p-10 flex flex-col min-h-fit justify-center items-center w-full">
        <Result
          status="error"
          title={t("insufficient_points")}
          subTitle={t("refill_points_description")}
          extra={[
            <Link to="/">
              <Button type="primary" key={t("back")}>
                {t("back")}
              </Button>
            </Link>,
            <Link to="/points_management/history">
              <Button key="transaction_history">
                {t("transaction_history")}
              </Button>
            </Link>,
          ]}
        ></Result>
      </div>
    );
  }
  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <div className=" w-full ">
        <Steps
          size="small"
          className="site-navigation-steps"
          current={currentStepper}
          items={stepperItems}
        />
      </div>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(_, { resetForm }) => {
          resetForm();
          setCurrentStepper(0);
        }}
      >
        {() => {
          switch (currentStepper) {
            case 0:
              return <SelectCarToRent setCurrentStepper={setCurrentStepper} />;
            case 1:
              return (
                <SelectDriversToRent setCurrentStepper={setCurrentStepper} />
              );
            case 2:
              return (
                <ContractInformations setCurrentStepper={setCurrentStepper} />
              );
            case 3:
              return <RecapRentContract />;
          }
        }}
      </Formik>
    </div>
  );
}
