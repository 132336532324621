import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import FormItem from "antd/es/form/FormItem";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Select,
  Slider,
  SliderSingleProps,
  Tag,
} from "antd";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import TextArea from "antd/es/input/TextArea";
import moment from "moment/moment";
import UploadWithDrag from "../../../../UI/UploadWithDrag";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { mutate } from "swr";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

function ContractInformations({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const { clients, isLoading } = useClientsData();
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const { contract } = values;

  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const marks: SliderSingleProps["marks"] = {
    0: "0",
    0.25: "1/4",
    0.5: "1/2",
    0.75: "3/4",
    1: "1",
  };

  const clientsToOptions = clients
    ? clients.map((client) => {
        return {
          label:
            client.fullName ||
            client.denomination ||
            client.vatNumber ||
            client.idNumber,
          value: client.clientID,
        };
      })
    : [];

  const handleCreateContract = async () => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        contract: {
          ...contract,
          galleryBefore: contract.galleryBefore,
          galleryAfter: contract.galleryAfter,
        },
      };

      const addedContact = await Axios.post("/rent/contract", data).then(
        (res) => res.data.message,
      );
      setFieldValue("addedContract", addedContact).then(() => {
        mutate("/enterprise/stats");
        setBtnIsLoading(false);
        setCurrentStepper((prevState) => prevState + 1);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <Form
        labelCol={{ span: 6 }}
        labelWrap
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        className="w-full md:w-2/3 "
      >
        <FormItem label={t("clients_lookup")}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder={t("clients_lookup")}
            optionFilterProp="children"
            value={contract.clientID || ""}
            style={{ width: "100%" }}
            options={clientsToOptions}
            disabled={isLoading}
            loading={isLoading}
            onChange={(value) => {
              setFieldValue("contract.clientID", value);
            }}
          />
        </FormItem>
        <FormItem label={t("kmCount")}>
          <RangePicker
            showTime
            minDate={dayjs()}
            onChange={(_, date) => {
              const [startDate, endDate] = date;
              setFieldValue(
                "contract.startDate",
                moment(startDate).toISOString(),
              );
              setFieldValue("contract.endDate", moment(endDate).toISOString());
            }}
          />

          {contract.endDate && contract.startDate && (
            <Tag color="green" className="!ml-4 ">
              {`${
                moment(contract.endDate).diff(
                  moment(contract.startDate),
                  "hours",
                ) / 24
              } ${t("days")}`}
            </Tag>
          )}
        </FormItem>
        <FormItem label={t("kmCount")}>
          <InputNumber
            addonAfter={t("KM")}
            placeholder={t("kmCount")}
            min={0}
            controls={false}
            onChange={(value) => {
              setFieldValue("contract.kmCount", value);
            }}
          />
        </FormItem>
        <FormItem label={t("dayPrice")}>
          <InputNumber
            placeholder={t("dayPrice")}
            controls={false}
            addonAfter={
              <div>
                {t("TND")}
                {contract.dayPrice &&
                  contract.endDate &&
                  contract.startDate && (
                    <span>
                      {` = ${
                        (moment(contract.endDate).diff(
                          moment(contract.startDate),
                          "hours",
                        ) /
                          24) *
                        contract.dayPrice
                      } ${t("TND")}`}{" "}
                    </span>
                  )}
              </div>
            }
            onChange={(value) => {
              setFieldValue("contract.dayPrice", value);
            }}
          />
        </FormItem>
        <FormItem label={t("FuelCount")}>
          <Slider
            marks={marks}
            step={null}
            defaultValue={0.5}
            max={1}
            onChange={(value) => {
              setFieldValue("contract.FuelCount", value);
            }}
          />
        </FormItem>
        <FormItem label={t("comment")}>
          <TextArea
            placeholder={t("comment")}
            rows={2}
            onChange={(value) => {
              setFieldValue("contract.comment", value.target.value);
            }}
          />
        </FormItem>
        <FormItem label={t("isRentWithDriver")}>
          <Select
            placeholder={t("isRentWithDriver")}
            optionFilterProp="children"
            value={contract.isRentWithDriver}
            style={{ width: "100%" }}
            options={[
              { label: t("yes"), value: true },
              { label: t("no"), value: false },
            ]}
            onChange={(value) => {
              setFieldValue("contract.isRentWithDriver", value);
            }}
          />
        </FormItem>
        <Divider />
        <FormItem label={t("galleryBefore")}>
          <UploadWithDrag
            hint={t("galleryBefore")}
            accept="image/*"
            onRemove={(file) => {
              if (file) {
                const fileName = file.response.files.files[0].name;
                const filesCleared = contract.galleryBefore.filter((file) =>
                  file.includes(fileName),
                );
                setFieldValue("contract.galleryBefore", filesCleared);
                return true;
              }
            }}
            setFileValue={(file) => {
              setFieldValue("contract.galleryBefore", [
                ...contract.galleryBefore,
                `/media/${file.name}`,
              ]);
            }}
            maxCount={5}
          />
        </FormItem>
      </Form>
      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
          loading={isBtnLoading}
          disabled={Object.entries(contract)
            .filter(([key]) => {
              return !Boolean(
                key === "galleryBefore" ||
                  key === "galleryAfter" ||
                  key === "isRentWithDriver",
              );
            })
            .some(([key, value]) => !value)}
          onClick={handleCreateContract}
        >
          {t("create_contract").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default ContractInformations;
