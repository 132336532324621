import React from "react";
import { CompanyInfosContent } from "./CompanyInfosContent";
import { CompanySideMenueContent } from "./CompanySideMenueContent";

export function CompanyManagementContent() {
  return (
    <div className="w-full  overflow-scroll p-10 flex flex-col md:flex-row gap-4">
      <CompanySideMenueContent />
      <CompanyInfosContent />
    </div>
  );
}
