import { Axios } from "./axios";
import useSWR, { SWRConfiguration } from "swr";
import { store } from "../Store/store";

export const swrConfig: SWRConfiguration = {
  fetcher: (url: string) =>
    Axios.get(url, {
      headers: {
        Authorization:
          window.localStorage.getItem("token") ||
          store.getState().authentication.token ||
          "",
      },
    }).then((res) => res.data),
  onErrorRetry: (_, __, ___, ____, { retryCount }) => {
    if (retryCount >= 2) return;
  },
};

export const useSwiftSWR = (key: string | null, options?: SWRConfiguration) => {
  return useSWR(key, { ...options });
};
