import React from "react";
import { RentContracts } from "../../../Types";
import { Tabs } from "antd";
import { SelectedCarDescription } from "../CompanyComponents/RentManagement/RentContractManagement/SelectedCarDescription";
import { RentPrintableContract } from "../../UI/Printables/RentPrintableContract";
import { useTranslation } from "react-i18next";
import { ExpandedRowDriver } from "../../UI/ExpandedRowDriver";

export function DashboardResultRenderer({
  contract,
}: {
  contract: RentContracts;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className="flex flex-col">
      <Tabs
        direction={language === "ar" ? "rtl" : "ltr"}
        defaultActiveKey="1"
        items={[
          {
            label: t("selected_car"),
            key: "1",
            children: (
              <SelectedCarDescription selectedCar={contract.rentedCar} />
            ),
          },
          {
            label: t("rent_divers"),
            key: "2",
            children: (
              <ExpandedRowDriver
                showFiles={false}
                drivers={contract.ContractDrivers}
              />
            ),
          },
          {
            label: t("driver_files"),
            key: "3",
            children: (
              <ExpandedRowDriver
                showContent={false}
                showFiles={true}
                drivers={contract.ContractDrivers}
              />
            ),
          },
          {
            label: t("rent_contracts"),
            key: "4",
            children: (
              <RentPrintableContract
                contract={contract}
                showPrint={false}
                isConsultant={true}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
