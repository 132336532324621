import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import CopyToClipboard from "../../../../UI/CopyToClipboard";
import { Switch } from "antd";

export const CompanyListingTableColumns: (
  t: any,
  userCompanyStatusHandler: any,
  openModal?: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (
  t,
  userCompanyStatusHandler,
) => [
  {
    title: t("companyID"),
    dataIndex: "companyID",
    key: "companyID",
    render: (value) => {
      return (
        <div className="flex w-full">
          <span className="text-gray-700">{t("copy_id")}</span>
          <CopyToClipboard input={value} showInput={false} />
        </div>
      );
    },
  },
  {
    title: t("numRegistre"),
    dataIndex: "rneID",
    key: "rneID",
    sorter: (a, b) => (a.rneID ? a.rneID.localeCompare(b.rneID) : false),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.rneID
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("matricule_fiscal"),
    dataIndex: "VAT",
    key: "VAT",
    render: (value) => {
      return <CopyToClipboard input={value} showIcon={false} />;
    },
    sorter: (a, b) => a.VAT.localeCompare(b.VAT),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.VAT.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("nomCommercialFr"),
    dataIndex: "rsLatin",
    key: "rsLatin",
    render: (value) => {
      return <CopyToClipboard input={value} showIcon={false} />;
    },
    sorter: (a, b) => a.rsLatin.localeCompare(b.rsLatin),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.rsLatin
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("nomCommercialAr"),
    dataIndex: "rsArabic",
    key: "rsArabic",
    render: (value) => {
      return <CopyToClipboard input={value} showIcon={false} />;
    },
    sorter: (a, b) => a.rsArabic.localeCompare(b.rsArabic),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.rsArabic
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },

  {
    title: t("typeRegistre"),
    dataIndex: "companyType",
    key: "companyType",
    sorter: (a, b) => a.companyType.localeCompare(b.companyType),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.companyType
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("account_points"),
    dataIndex: "CompanyPoints",
    key: "CompanyPoints",
    sorter: (a, b) => {
      const aPoints = a.CompanyPoints.length
        ? a.CompanyPoints[0].currentPoint
        : 0;
      const bPoints = b.CompanyPoints.length
        ? b.CompanyPoints[0].currentPoint
        : 0;
      return aPoints - bPoints;
    },
    sortDirections: ["descend", "ascend"],
    render: (value) => (value.length ? value[0].currentPoint : 0),
  },
  {
    title: t("isActive"),
    dataIndex: "isActive",
    key: "isActive",
    render: (value, record) => {
      return (
        <Switch
          defaultValue={value}
          onChange={async () => {
            await userCompanyStatusHandler(record.companyID, !value);
          }}
        />
      );
    },
  },
  {
    title: t("subscriptions"),
    dataIndex: "Subscription",
    key: "Subscription",
    render: (value) => {
      return value.length;
    },
    sorter: (a, b) => a.length - b.length,
    sortDirections: ["descend", "ascend"],
    width: 40,
  },
];

/*
{
    title: t("dateOfEst"),
    dataIndex: "dateOfEst",
    key: "dateOfEst",
    render: (date?: Date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: t("activity"),
    dataIndex: "activity",
    key: "activity",
    sorter: (a, b) => a.activity.localeCompare(b.activity),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.activity
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },

  {
    title: t("activeCompany"),
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive?: boolean) =>
      isActive ? (
        <Tag color="success">{t("yes")}</Tag>
      ) : (
        <Tag color="error">{t("no")}</Tag>
      ),

    onFilter: (value: any, record: any) =>
      record.isActive
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },

  {
    title: t("capital"),
    dataIndex: "capital",
    key: "capital",
    sorter: (a, b) => a.capital.localeCompare(b.capital),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.capital
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
{
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: Date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    render: (user: Users) => user.name, // Assuming Users has a 'name' field
  },
  
 */
