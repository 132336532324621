import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, List } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AppDispatch } from "../../../Store/store";
import { userLogoutAction } from "../../../Store/actions";

function LoginUserLogged() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: any) => state.authentication.user);
  const { t } = useTranslation();
  const avatar =
    (user?.avatar &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
        user?.avatar) ||
    "";

  return (
    <div className="h-full   w-full flex flex-col ">
      <p className="mb-10" style={{ fontFamily: "Inter" }}>
        {t("account_already_Logged")} :{" "}
      </p>
      <List bordered size="default">
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar size="large" crossOrigin="anonymous" src={avatar} />
            }
            title={user.name + " " + user.lastName}
            description={user.email}
          />
        </List.Item>
      </List>
      <Button
        type="primary"
        className="mb-5 !h-10 !bg-[#f46438] !hover:bg-[#E44C4D] !text-white w-full mt-10"
        onClick={() => navigate("/")}
      >
        {t("auth_login_btn")}
      </Button>
      <Button
        type="primary"
        className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757]"
        onClick={() => {
          navigate("/login");
          dispatch(userLogoutAction());
        }}
      >
        {t("login_another_account")}
      </Button>
    </div>
  );
}

export default LoginUserLogged;
