import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, List, QRCode, Select } from "antd";
import { useFormikContext } from "formik";
import { PlatformBillInitialValues } from "./AddPlatformBillContent";
import { useCompaniesData } from "../../../../../Hooks";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

export function SearchCompany({ setCurrentStepper }: propsT) {
  const { t } = useTranslation();
  const { companies, isLoading } = useCompaniesData();
  const { values, setFieldValue } =
    useFormikContext<PlatformBillInitialValues>();
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const company = useMemo(() => {
    if (!selectedCompany) {
      return null;
    }
    return companies.filter((comp) => comp.companyID === selectedCompany)[0];
  }, [selectedCompany, companies]);

  const companiesToOptions = companies
    ? companies.map((comp) => {
        return {
          label:
            comp.rsLatin?.toUpperCase() ||
            comp.rsArabic?.toUpperCase() ||
            comp.rneID ||
            comp.VAT,
          value: comp.companyID,
        };
      })
    : [];

  const nextStepHandler = () => {
    setBtnIsLoading(true);
    setFieldValue("companyID", selectedCompany).then((r) =>
      setCurrentStepper((prev) => {
        return prev + 1;
      }),
    );
    setBtnIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col items-center ">
      <div className="w-full md:w-1/2  flex flex-col md:flex-row items-center justify-evenly mb-8">
        <Select
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder={t("company_search")}
          optionFilterProp="children"
          value={values.companyID || selectedCompany}
          style={{ width: "100%" }}
          options={companiesToOptions}
          disabled={isLoading}
          loading={isLoading}
          onChange={(value) => {
            setSelectedCompany(value);
          }}
        />
      </div>

      {company && (
        <List bordered size="default" rootClassName="w-full md:w-2/4">
          <List.Item>
            <List.Item.Meta
              avatar={
                <QRCode
                  value={company?.companyID || "-"}
                  size={80}
                  bordered={false}
                />
              }
              title={
                <span>
                  {company.rsLatin?.toUpperCase() ||
                    company.rsArabic?.toUpperCase()}
                </span>
              }
              description={
                <div className="w-full flex flex-col gap-2">
                  <span>{company.mainAddress}</span>
                  <span>{company.phone}</span>
                  <span>{company.email}</span>
                </div>
              }
            />
          </List.Item>
        </List>
      )}

      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4"
        disabled={!selectedCompany}
        onClick={nextStepHandler}
        loading={isBtnLoading}
      >
        {t("next").toUpperCase()}
      </Button>
    </div>
  );
}
