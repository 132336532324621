import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { loginValidationSchema } from "../../../Validation";
import { Button } from "antd";
import OtpCustomInput from "../../UI/OtpCustomInput";

interface OtpVerificationFormProps {
  onSubmit: (
    values: { code: string },
    formikHelpers: FormikHelpers<{ code: string }>,
  ) => void | Promise<any>;
}

export function OtpVerificationForm({ onSubmit }: OtpVerificationFormProps) {
  const { t } = useTranslation();
  const initialFormState = { code: "" };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={loginValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue, submitForm, values }) => {
        return (
          <>
            <OtpCustomInput
              inputType="number"
              value={values.code}
              onChange={(value) => setFieldValue("code", value)}
              onPaste={(value) => setFieldValue("code", value.clipboardData)}
              numInputs={6}
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm  text-[#ff4d4f] flex justify-center items-center">
              {errors.code && t(errors.code)}
            </h3>
            <div className="w-full flex justify-between border-t border-t-[#E5E5E5] flex-col  mb-auto">
              <h3 className=" text-xs  ml-3 cursor-pointer mb-8 mt-4 w-full text-center ">
                {t("otp_didnt_receive_code")}{" "}
                <span className="text-[#f46438] font-medium">
                  {t("otp_resend_code")}
                </span>
              </h3>
              <Button
                type="primary"
                className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757]"
                onClick={submitForm}
              >
                {t("send")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
