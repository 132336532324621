import React, { useMemo, useState } from "react";
import {
  Button,
  Divider,
  Empty,
  Image,
  Radio,
  Rate,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { useCompanyRentContractsData } from "../../../../../Hooks/useCompanyRentContractsData";
import { useTranslation } from "react-i18next";
import { RentListingColumns } from "./RentListingColumns";
import Search from "antd/es/input/Search";
import { RentPrintableContract } from "../../../../UI/Printables/RentPrintableContract";
import { SelectedCarDescription } from "../RentContractManagement/SelectedCarDescription";
import { fileUrlHandler } from "../../../../../Utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ExpandedRowDriver } from "../../../../UI/ExpandedRowDriver";
import UploadWithDrag from "../../../../UI/UploadWithDrag";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

export function RentListingContent() {
  const { contracts, isLoading, mutate } = useCompanyRentContractsData();

  const { t } = useTranslation();

  const columns = RentListingColumns(t);

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<
    "ALL" | "PLANED" | "ON_GOING" | "FINISHED"
  >("ALL");

  const dataToDisplay = useMemo(() => {
    let filterContracts = contracts || [];

    if (searchStatus === "ALL") {
      filterContracts = contracts;
    }
    if (searchStatus === "PLANED") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(moment(new Date()).diff(moment(contract.startDate)) < 0);
      });
    }
    if (searchStatus === "ON_GOING") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(
          moment(new Date()).diff(moment(contract.startDate)) > 0 &&
            moment(new Date()).diff(moment(contract.endDate)) < 0,
        );
      });
    }
    if (searchStatus === "FINISHED") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(moment(new Date()).diff(contract.endDate) > 0);
      });
    }

    return search
      ? filterContracts.filter((contract) => {
          return Boolean(
            contract.rentContractID
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              contract.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.rentedCar.registrationNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.rentedCar.modal
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.ContractDrivers.some((driver) =>
                driver.driver.fullName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()),
              ) ||
              contract.ContractDrivers.some((driver) =>
                driver.driver.idNumber
                  ?.toLowerCase()
                  .includes(search.toLowerCase()),
              ) ||
              contract.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()),
          );
        })
      : filterContracts;
  }, [contracts, search, searchStatus]);

  const ExpandedRowCarGallery = (props: {
    gallery: string[];
    endDate?: string;
    rentContractID?: string;
  }) => {
    const [galleryAfter, setGalleryAfter] = useState<string[] | []>([]);
    const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

    const returnCarHandler = async (rentContractID: string) => {
      try {
        setBtnIsLoading(true);

        await Axios.put("/rent/contracts", {
          galleryAfter,
          rentContractID,
        });

        mutate();
        setBtnIsLoading(false);
      } catch (e: any) {
        setBtnIsLoading(false);
        toast.error(
          t(
            e?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };
    return (
      <div className="flex  gap-4 items-center w-full flex-wrap justify-evenly">
        {props.rentContractID && props.gallery.length === 0 && (
          <div className="flex flex-col gap-4 w-full items-center">
            <div className="  w-full md:w-1/2  ">
              <UploadWithDrag
                hint={t("galleryAfter")}
                accept="image/*"
                onRemove={(file) => {
                  if (file) {
                    const fileName = file.response.files.files[0].name;
                    const filesCleared = galleryAfter.filter((file: any) =>
                      file.includes(fileName),
                    );
                    setGalleryAfter(filesCleared);

                    return true;
                  }
                }}
                setFileValue={(file) => {
                  setGalleryAfter((prevState) => [
                    ...prevState,
                    `/media/${file.name}`,
                  ]);
                }}
                maxCount={5}
              />

              <div className="flex p-9 justify-center items-center">
                <Rate defaultValue={3} allowClear={false} />
              </div>

              <TextArea
                placeholder="textarea with clear icon"
                allowClear
                style={{ height: 120, resize: "none" }}
              />
            </div>
            <div className="w-full flex justify-center">
              <Button
                type="primary"
                className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                onClick={() => returnCarHandler(props?.rentContractID)}
                loading={isBtnLoading}
              >
                {t("update_return").toUpperCase()}
              </Button>
            </div>
          </div>
        )}

        {props?.gallery.length ? (
          <Image.PreviewGroup>
            {props?.gallery?.map((picture, index) => {
              return (
                <Image
                  height={150}
                  key={picture}
                  crossOrigin="anonymous"
                  src={fileUrlHandler(picture.replace("/media/", ""))}
                />
              );
            })}
          </Image.PreviewGroup>
        ) : (
          <>
            <Divider /> <Empty />
          </>
        )}
      </div>
    );
  };

  const expandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col p-8 " key={record.rentContractID}>
        <Tabs
          tabBarGutter={24}
          defaultActiveKey="1"
          items={[
            {
              label: t("selected_car"),
              key: "1",
              children: (
                <SelectedCarDescription selectedCar={record.rentedCar} />
              ),
            },
            {
              label: t("rent_divers"),
              key: "2",
              children: <ExpandedRowDriver drivers={record.ContractDrivers} />,
            },

            {
              label: t("rent_contracts"),
              key: "3",
              children: <RentPrintableContract contract={record} />,
            },
            {
              label: t("galleryBefore"),
              key: "4",
              children: JSON.parse(record?.galleryBefore).length ? (
                <ExpandedRowCarGallery
                  gallery={JSON.parse(record?.galleryBefore)}
                />
              ) : (
                <Empty />
              ),
            },
            {
              label: t("galleryAfter"),
              key: "5",
              children: (
                <ExpandedRowCarGallery
                  gallery={JSON.parse(record.galleryAfter)}
                  endDate={record.endDate}
                  rentContractID={record.rentContractID}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
          <Tooltip title={t("plate_search_info")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="PLANED">{t("PLANED")}</Radio.Button>
          <Radio.Button value="ON_GOING">{t("ON_GOING")}</Radio.Button>
          <Radio.Button value="FINISHED">{t("FINISHED")}</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        rowKey="rentContractID"
        size="small"
        loading={isLoading}
        dataSource={(dataToDisplay && dataToDisplay) || []}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}
