import React from "react";
import { Button, Table } from "antd";
import { useAgencesData } from "../../../../../Hooks/useAgencesData";
import { AgencesListingColumns } from "./AgencesListingColumns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export function ClientsListingContent() {
  const { isLoading, agences } = useAgencesData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = AgencesListingColumns(t);
  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="w-full  overflow-scroll mb-10  ">
        <div className="w-full flex gap-4 flex-col md:flex-row  justify-end">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
            onClick={() => {
              navigate("/agence_management/add_agence");
            }}
          >
            {t("add_agence").toUpperCase()}
          </Button>
        </div>
      </div>
      <Table
        rowKey="agenceID"
        size="small"
        columns={columns}
        loading={isLoading}
        dataSource={agences}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}
