import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { RentListingContent } from "../../Components/Features/CompanyComponents/RentManagement/RentListing/RentListingContent";

const RentListingPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("rent_listing")}>
        <div className="h-fit   w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <RentListingContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default RentListingPage;
