import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, QRCode } from "antd";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { NumberToLetter } from "../../../Utils/chiffresEnLettre";
import LocasafeLogo from "../../../Assets/SVGs/LocasafeLogo";

export const PlatformBillPrintable = ({
  showPrint = true,
  PlatformBill,
}: any) => {
  const { t } = useTranslation();
  const print = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  if (!PlatformBill) {
    return null;
  }

  const sommeEnLettre = NumberToLetter(
    PlatformBill.amount,
    "Dinars",
    "Millimes",
  );

  return (
    <div className="p-8 !bg-white rounded-xl overflow-x-scroll" dir="ltr">
      {showPrint && (
        <div className="flex gap-4 flex-col md:flex-row mb-7 ">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
            onClick={handlePrint}
          >
            {t("print_bill").toUpperCase()}
          </Button>
        </div>
      )}

      <div
        className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10"
        dir="ltr"
      >
        <div ref={print} className="flex flex-col min-w-[800px]">
          <div className="flex flex-col  bg-white rounded-xl dark:bg-neutral-800 p-12 pb-24 min-h-[842px]">
            <div className="flex justify-between">
              <div className="flex flex-col gap-6 ">
                <LocasafeLogo width={200} height={100} />
                <QRCode
                  value={PlatformBill.platformBillID}
                  className="pt-6"
                  bordered={false}
                  size={100}
                />
              </div>

              <div className="text-end flex flex-col justify-end">
                <h1 className="mt-2 text-lg md:text-xl font-semibold">
                  LOCASAFE
                </h1>
                <address className="mt-4 not-italic text-gray-800 dark:text-neutral-200 w-3/5  self-end">
                  <span className="">TUNISIE</span>
                  <br />
                  MF : 1988888
                </address>
                <p className="block text-sm font-medium text-gray-300 ">
                  contact@locasage.tn
                </p>
                <p className="block text-sm font-medium text-gray-300 ">
                  71090909
                </p>
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-neutral-200 mt-8">
                  FACTURE #
                </h2>
                <span className="mt-1 block text-gray-500 dark:text-neutral-500">
                  {PlatformBill.platformBillID}
                </span>
              </div>
            </div>

            <div className="mt-8 grid sm:grid-cols-2 gap-3">
              <div>
                <h3 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                  Pour:
                </h3>
                <h3 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                  {PlatformBill?.company?.rsLatin?.toUpperCase() ||
                    PlatformBill?.company?.rsArabic?.toUpperCase() ||
                    ""}
                </h3>
                <address className="mt-2 not-italic text-gray-500 dark:text-neutral-500">
                  {PlatformBill.company.VAT || PlatformBill.company.rneID}
                  <br />
                  {PlatformBill.company.mainAddress}
                  <br />
                  {PlatformBill.company.email}
                  <br />
                  {PlatformBill.company.phone}
                  <br />
                </address>
              </div>

              <div className="sm:text-end space-y-2">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      Crée le:
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      {moment(PlatformBill.createdAt).format(
                        "DD/MM/YYYY HH:MM",
                      )}
                    </dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      Valable jusqu'au:
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      {moment(PlatformBill.validUntil).format(
                        "DD/MM/YYYY HH:MM",
                      )}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="border border-gray-200 p-4 rounded-lg space-y-4 dark:border-neutral-700">
                <div className="hidden sm:grid sm:grid-cols-5">
                  <div className="sm:col-span-2 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Description
                  </div>
                  <div className="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Quantité
                  </div>
                  <div className="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Prix Unitaire TTC
                  </div>
                  <div className="text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Total TTC
                  </div>
                </div>

                <div className="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>
                {PlatformBill?.PlatformBillsItems?.map(
                  (item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <div className="grid grid-cols-3 sm:grid-cols-5 gap-2">
                          <div className="col-span-full sm:col-span-2">
                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                              Description
                            </h5>
                            <p className="font-medium text-gray-800 dark:text-neutral-200">
                              {item.name}
                            </p>
                          </div>
                          <div>
                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                              Quantité
                            </h5>
                            <p className="text-gray-800 dark:text-neutral-200">
                              {item.quantity}
                            </p>
                          </div>
                          <div>
                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                              Prix Unitaire
                            </h5>
                            <p className="text-gray-800 dark:text-neutral-200">
                              {item.price} DNT
                            </p>
                          </div>

                          <div>
                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                              Amount
                            </h5>
                            <p className="sm:text-end text-gray-800 dark:text-neutral-200">
                              {item.total} DNT
                            </p>
                          </div>
                        </div>
                        <div className="sm:hidden border-b border-gray-200 dark:border-neutral-700"></div>
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>

            <div className="mt-8 flex sm:justify-end">
              <div className="w-full max-w-2xl sm:text-end space-y-2">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      Sous-Total HT
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      {PlatformBill.amount - (19 / 100) * PlatformBill.amount}{" "}
                      DNT
                    </dd>
                  </dl>

                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      TVA 19%:
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      {(19 / 100) * PlatformBill.amount} DNT
                    </dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      TIMBRE :
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      1.000 DNT
                    </dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                      Total:
                    </dt>
                    <dd className="col-span-2 text-gray-500 dark:text-neutral-500">
                      {PlatformBill.amount + 1} DNT
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <h4 className="text-xs font-semibold text-gray-800 dark:text-neutral-200 mt-8 justify-center">
              {sommeEnLettre.includes("Dinars")
                ? sommeEnLettre.toUpperCase()
                : `${sommeEnLettre.toUpperCase()} DINARS`}
            </h4>
            {PlatformBill.note && (
              <h4 className="text-xs font-semibold text-gray-400 dark:text-neutral-200 mt-8 justify-center">
                Note: {PlatformBill.note}
              </h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
