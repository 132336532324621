import React from "react";
import { Col, Statistic } from "antd";
import { LikeOutlined } from "@ant-design/icons";

export function CompanyDashBordStats() {
  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <div className="w-full flex justify-evenly my-8">
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Feedback"
            value={1128}
            prefix={<LikeOutlined />}
            className="flex flex-col justify-center items-center"
          />
        </Col>
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Feedback"
            value={1128}
            prefix={<LikeOutlined />}
            className="flex flex-col justify-center items-center"
          />
        </Col>
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Unmerged"
            value={93}
            suffix="/ 100"
            className="flex flex-col justify-center items-center"
          />
        </Col>
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Unmerged"
            value={93}
            suffix="/ 100"
            className="flex flex-col justify-center items-center"
          />
        </Col>
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Unmerged"
            value={93}
            suffix="/ 100"
            className="flex flex-col justify-center items-center"
          />
        </Col>
        <Col span={4} className="flex justify-center items-center">
          <Statistic
            title="Unmerged"
            value={93}
            suffix="/ 100"
            className="flex flex-col justify-center items-center"
          />
        </Col>
      </div>
    </div>
  );
}
