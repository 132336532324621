import React, { Dispatch, SetStateAction, useState } from "react";
import { useFleetData } from "../../../../../Hooks";
import { Button, DatePicker, Divider, Empty, Form, Input, Spin } from "antd";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { SelectedCarDescription } from "./SelectedCarDescription";
import { t } from "i18next";
import { Drivers } from "../../../../../Types";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import moment from "moment";
import dayjs from "dayjs";
import { SelectedDriverDescription } from "./SelectedDriverDescription";

function SelectDriversToRent({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const { fleet, isLoading: isFleetLoading } = useFleetData();
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const [selectedDrivers, setSelectedDrivers] = useState<Drivers[] | []>([]);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const [idNumber, setIdNumber] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);

  if (isFleetLoading) {
    return (
      <div className="w-full h-fit flex flex-col gap-4 items-center">
        <Spin />
      </div>
    );
  }

  const handleSubmit = () => {
    const drivers = selectedDrivers.map((driver, index) => {
      return {
        DriverID: driver.driverID,
        driverType: index === 0 ? "FIRST" : "SECOND",
      };
    });
    setFieldValue("drivers", drivers).then(() => {
      setCurrentStepper((prevState) => prevState + 1);
    });
  };

  const searchForDriverHandler = async () => {
    try {
      setBtnIsLoading(true);
      const params = new URLSearchParams({
        idNumber,
        dateOfBirth: dateOfBirth || "",
      });

      const driver = (await Axios.get("/driver", { params }).then(
        (res) => res.data.message,
      )) as Drivers[] | [];

      if (!driver.length) {
        toast.info(t("DRIVER_NOT_FOUND"));
      } else {
        if (
          selectedDrivers.some(
            (drivers) => drivers.driverID === driver?.[0].driverID,
          )
        ) {
          toast.info(t("DRIVER_ALREADY_SELECTED"));
        } else {
          setSelectedDrivers((prevState) => [...prevState, ...driver]);
          setIdNumber("");
          setDateOfBirth(undefined);
        }
      }

      setBtnIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setBtnIsLoading(false);
    }
  };

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      {fleet?.length ? (
        <div className="w-full flex flex-col gap-4 items-center">
          <h3 className="text-sm font-bold">
            {t("selected_car").toUpperCase()}
          </h3>
          <SelectedCarDescription
            selectedCar={
              fleet?.filter((car) => car.fleetID === values.fleetID)[0]
            }
          />
        </div>
      ) : null}

      <Divider />

      <div className="w-full md:w-2/3 flex flex-col md:flex-row gap-4 justify-center items-center ">
        <Form.Item label={t("idNumber")}>
          <Input
            value={idNumber || ""}
            placeholder={t("idNumber")}
            disabled={selectedDrivers.length === 2}
            onChange={({ target: { value } }) => {
              setIdNumber(value);
            }}
          />
        </Form.Item>
        <Form.Item label={t("dateOfBirth")}>
          <DatePicker
            value={
              dateOfBirth
                ? dayjs(dateOfBirth, "YYYY-MM-DD").add(1, "day")
                : undefined
            }
            format="DD/MM/YYYY"
            disabled={selectedDrivers.length === 2}
            onChange={(_, date) => {
              console.log(moment(date).toISOString());
              setDateOfBirth(moment(date, "DD/MM/YYYY").toISOString());
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          loading={isBtnLoading}
          disabled={selectedDrivers.length === 2 || !idNumber || !dateOfBirth}
          onClick={searchForDriverHandler}
        >
          {t("search_driver").toUpperCase()}
        </Button>
      </div>

      <Divider />

      {selectedDrivers.length ? (
        <>
          <div>
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mb-4  "
              onClick={() => {
                setSelectedDrivers([]);
              }}
            >
              {t("clear_drivers").toUpperCase()}
            </Button>
          </div>
          {selectedDrivers.map((selectedDriver, index) => {
            return (
              <SelectedDriverDescription
                selectedDriver={selectedDriver}
                isFirst={index === 0}
                key={selectedDriver.driverID}
              />
            );
          })}
        </>
      ) : (
        <Empty />
      )}

      <div>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          disabled={selectedDrivers.length < 1}
          onClick={handleSubmit}
        >
          {t("submit").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default SelectDriversToRent;
