import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Form, Input, InputNumber, Result } from "antd";
import FormItem from "antd/es/form/FormItem";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface InitialValuesT {
  agenceName: string | null;
  agenceAddress: string | null;
  agenceCode: number | null;
}
export function AddAgenceContent() {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const initialValues: InitialValuesT = {
    agenceName: null,
    agenceAddress: null,
    agenceCode: null,
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
      };

      await Axios.post("/enterprise/agence", data);
      formikHelpers.setValues(initialValues);
      setIsSuccess(true);

      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="w-full  overflow-scroll p-10 ">
      {!isSuccess ? (
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem name="agenceName" label={t("agenceName")}>
                  <Input />
                </FormItem>

                <FormItem name="agenceAddress" label={t("agenceAddress")}>
                  <Input />
                </FormItem>

                <FormItem name="agenceCode" label={t("agenceCode")}>
                  <InputNumber
                    controls={false}
                    min={0}
                    style={{ width: "100%" }}
                  />
                </FormItem>

                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    loading={isBtnLoading}
                  >
                    {t("add_agence").toUpperCase()}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("agence_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}
