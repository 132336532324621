import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import { Button, DatePicker, Form, InputNumber, Modal, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSWRConfig } from "swr";

function CompanyAddPointsModal({ record }: any) {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const initialValues = {
    companyID: record.companyID,
    points: null,
    extendUntil: null,
    transactionType: "CREDIT",
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setIsBtnLoading(true);
      const data = {
        ...values,
      };

      await Axios.post("/platform/companyPoints", data);
      formikHelpers.resetForm();
      mutate("/platform/companies").then(() => {
        setIsBtnLoading(false);
        setOpen(false);
      });
    } catch (e: any) {
      setIsBtnLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  return (
    <div className="w-full flex flex-col">
      {open ? (
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          title={t("update_company_points_validation")}
          footer={null}
        >
          <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
            {({ values, submitForm, setFieldValue }) => {
              return (
                <Form
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  disabled={false}
                  initialValues={values}
                  style={{ marginTop: "40px" }}
                >
                  <FormItem name="transactionType" label={t("transactionType")}>
                    <Select
                      allowClear
                      placeholder={t("transactionType")}
                      optionFilterProp="children"
                      value={values.transactionType}
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: t("CREDIT"),
                          value: "CREDIT",
                        },
                        {
                          label: t("DEBIT"),
                          value: "DEBIT",
                        },
                      ]}
                      onChange={(value) => {
                        setFieldValue("transactionType", value);
                      }}
                    />
                  </FormItem>
                  <FormItem name="points" label={t("points_management")}>
                    <InputNumber
                      controls={false}
                      min={0}
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setFieldValue("points", value);
                      }}
                    />
                  </FormItem>
                  <FormItem name="extendUntil" label={t("validUntil")}>
                    <DatePicker
                      showToday
                      format="DD/MM/YYYY"
                      disabledDate={(day) => {
                        return Boolean(
                          moment(new Date()).diff(moment(day.toISOString())) >
                            0,
                        );
                      }}
                      onChange={(_, date) => {
                        setFieldValue(
                          "extendUntil",
                          moment(date, "DD/MM/YYYY").toISOString(),
                        );
                      }}
                    />
                  </FormItem>
                  <div className="w-full flex justify-center">
                    <Button
                      type="primary"
                      className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                      onClick={submitForm}
                      loading={isBtnLoading}
                    >
                      {t("add").toUpperCase()}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      ) : null}
      <div className="w-full flex justify-between flex-col py-7 mb-auto">
        <div>
          <Button
            type="primary"
            className="mb-5 !h-10 !bg-[#f46438] !hover:bg-[#E44C4D] !text-white"
            onClick={() => setOpen(true)}
          >
            {t("update_company_points_validation")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CompanyAddPointsModal;
