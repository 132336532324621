import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import { SearchHistoryPage } from "../Pages";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { ConsultantUsersManagementPage } from "../Pages/CompanyPages/ConsultantUsersManagementPage";

export function ConsultantRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/users_management/users_management"
        element={
          <PrivateRoute>
            <ConsultantUsersManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/consultant/history"
        element={
          <PrivateRoute>
            <SearchHistoryPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
