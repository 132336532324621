import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Result,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Formik } from "formik";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { SubscriptionPrices } from "../../../../../Types";
import CopyToClipboard from "../../../../UI/CopyToClipboard";

interface PropsT {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  mutate: any;
}

interface initialValuesT {
  subscriptionName: null | string;
  price: null | number;
  isRenewable: boolean;
  maxVehicle: null | number;
  description: null | string;
  subscriptionPeriod: "MONTHLY" | "TRIMESTERLY" | "ANNUALLY";
}
export function AddSubscriptionPriceModal({
  isOpen,
  setIsOpen,
  mutate,
}: PropsT) {
  const { t } = useTranslation();
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const [addedSubscriptionPrice, setAddedSubscriptionPrice] =
    useState<SubscriptionPrices | null>(null);

  const initialValues: initialValuesT = {
    subscriptionName: null,
    price: null,
    isRenewable: true,
    maxVehicle: null,
    description: null,
    subscriptionPeriod: "ANNUALLY",
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const addSubscriptionPriceHandler = async (values: any) => {
    try {
      setBtnIsLoading(true);
      const addedSubscriptionPrices = await Axios.post(
        "/platform/subscription/prices",
        values,
      ).then((res) => res?.data?.message);
      mutate(() => true, undefined, false).then(() => {
        setAddedSubscriptionPrice(addedSubscriptionPrices);
        setBtnIsLoading(false);
      });
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setBtnIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      title={t("create_new_subscription_price")}
      onCancel={handleCancel}
      footer={null}
    >
      <>
        {!addedSubscriptionPrice && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => addSubscriptionPriceHandler(values)}
          >
            {({ values, setFieldValue, submitForm }) => {
              const isFieldMissing =
                Boolean(values.price) &&
                Boolean(values.subscriptionName) &&
                Boolean(values.description) &&
                Boolean(values.maxVehicle);

              return (
                <>
                  <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    disabled={false}
                    style={{ maxWidth: 1000, width: "100%", marginTop: "40px" }}
                    initialValues={values}
                    onValuesChange={(fieldKeyValue, values) => {
                      const fieldKey = Object.keys(fieldKeyValue)[0];
                      const fieldValue = fieldKeyValue[fieldKey];
                      setFieldValue(fieldKey, fieldValue);
                    }}
                  >
                    <Form.Item
                      label={t("subscriptionName")}
                      name="subscriptionName"
                    >
                      <Input value={values.subscriptionName || ""} />
                    </Form.Item>
                    <Form.Item
                      label={t("subscriptionDescription")}
                      name="description"
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item label={t("maxVehicle")} name="maxVehicle">
                      <InputNumber
                        style={{ width: 150 }}
                        controls={false}
                        min={0}
                        value={values.maxVehicle || ""}
                      />
                    </Form.Item>
                    <Form.Item label={t("price")} name="price">
                      <InputNumber
                        style={{ width: 150 }}
                        controls={false}
                        min={0}
                        suffix={t("TND")}
                        value={values.price || ""}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("subscriptionPeriod")}
                      name="subscriptionPeriod"
                    >
                      <Select
                        style={{ width: 150 }}
                        options={[
                          { value: "MONTHLY", label: t("MONTHLY") },
                          { value: "TRIMESTERLY", label: t("TRIMESTERLY") },
                          { value: "ANNUALLY", label: t("ANNUALLY") },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item label={t("isRenewable")} name="isRenewable">
                      <Radio.Group>
                        <Radio value={true}>{t("yes")}</Radio>
                        <Radio value={false}> {t("no")} </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                  <div className="w-full flex gap-4 flex-col md:flex-row   justify-end">
                    <Button
                      type="default"
                      className="!h-8  !text-xs !px-7"
                      onClick={handleCancel}
                    >
                      {t("cancel").toUpperCase()}
                    </Button>
                    <Button
                      type="primary"
                      className="!h-8 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-7 !text-white"
                      loading={isBtnLoading}
                      onClick={submitForm}
                      disabled={!isFieldMissing}
                    >
                      {t("create").toUpperCase()}
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        )}

        {addedSubscriptionPrice && (
          <div className="w-full flex flex-col gap-6 items-center">
            <Result
              status="success"
              title={t("created_subscription_price")}
              subTitle={
                <div className="flex flex-col">
                  <CopyToClipboard
                    input={addedSubscriptionPrice.subscriptionPriceID}
                  />
                  <CopyToClipboard
                    input={addedSubscriptionPrice.subscriptionName}
                  />
                  <CopyToClipboard
                    input={`${addedSubscriptionPrice.price} ${t("TND")}`}
                  />
                </div>
              }
            />
          </div>
        )}
      </>
    </Modal>
  );
}
