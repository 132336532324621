import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { AddCompanyContent } from "../../Components/Features/PlatformComponents/CompaniesManagement/AddCompany";

export function AddCompanyPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_company")}>
        <div className="h-fit  w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl ">
          <AddCompanyContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
