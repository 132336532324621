import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { RentPrintableContract } from "../../Components/UI/Printables/RentPrintableContract";

export const FleetManagementPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("FleetManagement")}>
        <div className="w-3/4">
          <RentPrintableContract />
        </div>
      </PageLayout>
    </AppLayout>
  );
};
