import React, { SVGProps } from "react";

function SwiftLogoColored(props: SVGProps<any>) {
  return (
    <svg
      viewBox="0 0 50 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.83,48.19a54.1,54.1,0,0,0,5.61,5.12c3.55,2.81,5.37,4.22,7.46,4.26,2.29,0,4.15-1.4,7.87-4.28a51,51,0,0,0,5.65-5.1"
        transform="translate(-3.87 -3.35)"
        fill="none"
        stroke="#f5a15a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.73"
      />
      <path
        d="M53,31.33c.6-2,1-3.69,1.31-4.91a49.4,49.4,0,0,0,.87-11.69,2,2,0,0,0-.31-1.06c-.69-1-2.34-.94-2.8-.93-3.78,0-9.65-2.25-10.44-2.55-1.35-.53-6.91-2.86-9.38-5a3.25,3.25,0,0,0-2.05-.93A3.39,3.39,0,0,0,28,5.15c-2.42,2-7.85,4.49-10.62,5.41-3.14,1-6.41,2.14-9.2,2.11a3.63,3.63,0,0,0-2.67.87,3.17,3.17,0,0,0-.73,2,40.9,40.9,0,0,0,1,10.62C6.11,27.36,6.53,29,7.07,31"
        transform="translate(-3.87 -3.35)"
        fill="none"
        stroke="#f5a15a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.73"
      />
      <path
        d="M50.21,24.73a2.56,2.56,0,0,0-1.13-1.57A3.11,3.11,0,0,0,48,22.71l-3.51.05a14.58,14.58,0,0,0-1.76-3.09,14.41,14.41,0,0,0-2.95-2.91l-19.69.15A15.72,15.72,0,0,0,15.79,23l-3.85,0a4.41,4.41,0,0,0-.9.69,3.72,3.72,0,0,0-1,1.35c-.14.37-.22.55-.18.69.12.43,1.27.73,5.51.31l-4.64,4.84L10.87,46l5.84,0V44.26l27.09-.2V46l6.13,0-.11-15.27L45,25.93c.67,0,5,.31,5.22-.72a1,1,0,0,0,0-.48m-29.49-5.4,18.58-.14q1.73,3,3.46,5.95l-25.48.19,3.44-6M15.74,37a2.49,2.49,0,1,1,2.47-2.51A2.49,2.49,0,0,1,15.74,37M39,38.46l-17.78.13-.54-3.1,18.82-.14Zm8.13-4.14a2.49,2.49,0,1,1-2.5-2.46,2.48,2.48,0,0,1,2.5,2.46"
        transform="translate(-3.87 -3.35)"
        fill="#14438a"
      />
    </svg>
  );
}

export default SwiftLogoColored;
