import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { DatePicker, InputNumber, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useFleetData } from "../../../../Hooks";
import { useMediaQuery } from "@mui/material";

interface initialValuesT {
  startDate: Date | string | null;
  endDate: Date | string | null;
  dayPrice: number | null;
  selectedCar: string | null;
}

function CompanyDashboardCalculator() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { fleet, isLoading } = useFleetData();

  const initialValues: initialValuesT = {
    startDate: null,
    endDate: null,
    dayPrice: null,
    selectedCar: null,
  };
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const fleetAsOptions = useMemo(() => {
    return fleet?.map((car) => {
      return {
        value: car.fleetID,
        label: (
          <div className="flex gap-5 md:justify-between flex-col justify-center   md:flex-row  ">
            <div className="flex gap-4 ">
              {isSmallDevice ? null : (
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fcec4d3d0af2e0aa4b08ad6a545cafa51449c6aec3c7efd37a67964f216a9bc?apiKey=47918fdd8d134febb6c66661b288dec3&"
                  className="shrink-0 max-w-full aspect-[1.89] w-[132px]"
                />
              )}
              <div className="flex flex-col px-5 my-auto">
                <div className="text-base font-bold tracking-tight leading-6 text-gray-900">
                  {car.manufacturer.replaceAll("_", " ")}{" "}
                  {car.modal.toUpperCase().replaceAll("_", " ")}
                </div>
                <div className="text-xs font-medium tracking-tight text-slate-400">
                  {car.registrationNumber.replaceAll("/", ` ${t("TU")} `)}
                </div>
              </div>
            </div>
            <div className="flex flex-col px-5 my-auto text-xs font-medium tracking-tight text-right text-slate-400">
              <div>
                {t("transmission")} : <b> {t(car.transmission)}</b>
              </div>
              <div>
                {t("fuel")} : <b> {t(car.fuel || "")}</b>
              </div>
              <div>
                {car.numberHorses} {t("horse_power")}
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [fleet]);

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({ values, setFieldValue }) => {
        return (
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col gap-6 w-full md:w-3/5 ">
              <div className="flex flex-col items-start p-6 bg-white rounded-xl w-full max-md:px-5">
                <div className="flex gap-5 justify-between self-stretch leading-[150%] max-md:flex-wrap max-md:max-w-full">
                  <div className="flex flex-col">
                    <div className="text-xl font-bold tracking-tight text-gray-900">
                      {t("rental_info")}
                    </div>
                    <div className="mt-1 text-sm font-medium tracking-tight text-slate-400">
                      {t("rental_info_description")}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 mt-8">
                  <div className="flex flex-col justify-center items-center p-1 my-auto w-4 h-4 bg-blue-600 bg-opacity-30 rounded-[70px]">
                    <div className="shrink-0 w-2 h-2 bg-blue-600 rounded-full" />
                  </div>
                  <div className="text-base font-semibold tracking-tight text-gray-900">
                    {t("pick-up")}
                  </div>
                </div>
                <div className="flex gap-5 self-stretch mt-6 leading-[150%] max-md:flex-wrap">
                  <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
                    <div className="flex gap-5 justify-between    mt-4 text-sm font-medium tracking-tight rounded-xl  text-slate-400 max-md:px-5">
                      <DatePicker
                        minDate={dayjs(new Date())}
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY HH:mm"
                        size="large"
                        showTime
                        value={
                          values.startDate ? dayjs(values.startDate) : null
                        }
                        onChange={(_, date) => {
                          setFieldValue(
                            "startDate",
                            moment(date, "DD/MM/YYYY HH:mm").toISOString(),
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 mt-8">
                  <div className="flex flex-col justify-center items-center p-1 my-auto w-4 h-4 bg-blue-400 bg-opacity-30 rounded-[70px]">
                    <div className="shrink-0 w-2 h-2 bg-blue-400 rounded-full" />
                  </div>
                  <div className="text-base font-semibold tracking-tight text-gray-900">
                    {t("drop-off")}
                  </div>
                </div>
                <div className="flex gap-5 self-stretch mt-6 leading-[150%] max-md:flex-wrap">
                  <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
                    <div className="flex gap-5 justify-between  mt-4 text-sm font-medium tracking-tight rounded-xl  text-slate-400 max-md:px-5">
                      <DatePicker
                        minDate={dayjs(new Date())}
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY HH:mm"
                        showTime
                        size="large"
                        value={values.endDate ? dayjs(values.endDate) : null}
                        onChange={(_, date) => {
                          setFieldValue(
                            "endDate",
                            moment(date, "DD/MM/YYYY HH:mm").toISOString(),
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 mt-8">
                  <div className="flex flex-col justify-center items-center p-1 my-auto w-4 h-4 bg-blue-600 bg-opacity-30 rounded-[70px]">
                    <div className="shrink-0 w-2 h-2 bg-blue-600 rounded-full" />
                  </div>
                  <div className="text-base font-semibold tracking-tight text-gray-900">
                    {t("dayPrice")}
                  </div>
                </div>
                <div className="flex gap-5 self-stretch mt-6 leading-[150%] max-md:flex-wrap">
                  <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
                    <div className="flex gap-5 justify-between    mt-4 text-sm font-medium tracking-tight rounded-xl  text-slate-400 max-md:px-5">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        addonAfter={t("TND")}
                        value={values.dayPrice}
                        onChange={(value) => {
                          setFieldValue("dayPrice", value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 mt-8">
                  <div className="flex flex-col justify-center items-center p-1 my-auto w-4 h-4 bg-blue-400 bg-opacity-30 rounded-[70px]">
                    <div className="shrink-0 w-2 h-2 bg-blue-400 rounded-full" />
                  </div>
                  <div className="text-base font-semibold tracking-tight text-gray-900">
                    {t("select_car")}
                  </div>
                </div>
                <div className="flex gap-5 self-stretch mt-6 leading-[150%] max-md:flex-wrap max-w-full">
                  <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
                    <div className="flex gap-5 justify-between  mt-4 text-sm font-medium tracking-tight rounded-xl  text-slate-400 max-md:px-5">
                      <Select
                        direction={language === "ar" ? "rtl" : "ltr"}
                        placeholder={t("select_car")}
                        defaultValue={values.selectedCar}
                        style={{ width: "100%", height: "fit-content" }}
                        allowClear
                        loading={isLoading}
                        disabled={isLoading}
                        options={fleetAsOptions}
                        onChange={(value) => {
                          setFieldValue("selectedCar", value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="flex flex-col gap-6 w-full md:w-2/5 ">
              <div className="flex flex-col p-6 bg-white rounded-xl w-full max-md:px-5">
                <div className="text-xl font-bold tracking-tight leading-8 text-gray-900 max-md:max-w-full">
                  {t("calculation_result")}
                </div>
                <div className="mt-1 text-sm font-medium tracking-tight leading-5 text-slate-400 max-md:max-w-full">
                  {t("calculation_result_description")}
                </div>

                <div className="shrink-0 mt-8 h-px border border-solid bg-slate-300 bg-opacity-40 border-slate-300 border-opacity-40 max-md:max-w-full" />

                <div className="flex gap-5 justify-between mt-8 text-base tracking-tight leading-6 whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                  <div className="font-medium text-slate-400 capitalize">
                    {t("days")}
                  </div>
                  <div className="font-semibold text-right text-gray-900">
                    {values.endDate && values.startDate
                      ? Number(
                          moment(values.endDate).diff(
                            moment(values.startDate),
                            "hours",
                          ) / 24,
                        ).toFixed(3)
                      : "-"}{" "}
                    {t("days")}
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-6 text-base tracking-tight leading-6 whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                  <div className="font-medium text-slate-400 capitalize">
                    {t("dayPrice")}
                  </div>
                  <div className="font-semibold text-right text-gray-900">
                    {values.dayPrice || "-"} {t("TND")}
                  </div>
                </div>

                <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap">
                  <div className="flex flex-col leading-[150%]">
                    <div className="text-xl font-bold tracking-tight text-gray-900">
                      {t("total_rent_price")}
                    </div>
                    <div className="mt-1 text-sm font-medium tracking-tight text-slate-400">
                      {t("total_included_taxes")}
                    </div>
                  </div>
                  <div className="text-3xl font-bold text-right text-gray-900">
                    {values.dayPrice && values.startDate && values.endDate
                      ? Number(
                          Number(
                            Number(
                              moment(values.endDate).diff(
                                moment(values.startDate),
                                "hours",
                              ) / 24,
                            ).toFixed(3),
                          ) * values.dayPrice,
                        ).toFixed(3)
                      : "-"}{" "}
                    {t("TND")}
                  </div>
                </div>
              </div>
              {/*
              <div className="flex flex-col p-6 bg-white rounded-xl w-full max-md:px-5">
                <div className="max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow text-sm font-medium tracking-tight leading-5 text-gray-900 max-md:mt-8">
                        <div className="text-xl font-bold tracking-tight">
                          Billing Info
                        </div>
                        <div className="mt-1 text-slate-400">
                          Please enter your billing info
                        </div>
                        <div className="mt-8 text-base font-semibold tracking-tight">
                          Name
                        </div>
                        <div className="justify-center items-start px-8 py-6 mt-4 rounded-xl bg-neutral-100 text-slate-400 max-md:px-5">
                          Your name
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow mt-8 text-sm font-medium tracking-tight leading-5 text-slate-400 max-md:mt-10">
                        <div className="self-end text-right">Step 1 of 4</div>
                        <div className="mt-8 text-base font-semibold tracking-tight text-gray-900">
                          Phone Number
                        </div>
                        <div className="justify-center items-start px-8 py-6 mt-4 rounded-xl bg-neutral-100 max-md:px-5">
                          Phone number
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 mt-6 leading-[150%] max-md:flex-wrap">
                  <div className="flex flex-col flex-1 grow shrink-0 whitespace-nowrap basis-0 w-fit">
                    <div className="text-base font-semibold tracking-tight text-gray-900">
                      Address
                    </div>
                    <div className="justify-center items-start px-8 py-6 mt-4 text-sm font-medium tracking-tight rounded-xl bg-neutral-100 text-slate-400 max-md:px-5">
                      Address
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
                    <div className="text-base font-semibold tracking-tight text-gray-900">
                      Town / City
                    </div>
                    <div className="justify-center items-start px-8 py-6 mt-4 text-sm font-medium tracking-tight rounded-xl bg-neutral-100 text-slate-400 max-md:px-5">
                      Town or city
                    </div>
                  </div>
                </div>
              </div>
*/}
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default CompanyDashboardCalculator;
