import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import PointsTransactionsListing from "../../Components/Features/CompanyComponents/PointsTransactions/PointsTransactionsListing";

export const PointsTransactionsPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("transaction_history")}>
        <div className="h-fit w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <PointsTransactionsListing />
        </div>
      </PageLayout>
    </AppLayout>
  );
};
