import React from "react";
import logog from "../../Assets/Images/logo.png";

const Page: React.FC = () => {
  return (
    <div className="relative w-full bg-gray-900">
      <img
        src={logog}
        alt="logo"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4 h-auto max-w-full max-h-full"
        style={{ maxWidth: "300px", maxHeight: "300px" }}
      />
    </div>
  );
};

export default Page;