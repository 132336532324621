import React, { Dispatch, SetStateAction } from "react";
import { PlatformBillEditableRow } from "./PlatformBillEditableRow";
import { Button } from "antd";
import { useFormikContext } from "formik";
import { PlatformBillInitialValues } from "./AddPlatformBillContent";
import { useTranslation } from "react-i18next";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

function BillItems({ setCurrentStepper }: propsT) {
  const { values } = useFormikContext<PlatformBillInitialValues>();
  const { t } = useTranslation();
  const nextStepHandler = () => {
    setCurrentStepper((prev) => {
      return prev + 1;
    });
  };

  return (
    <div className="w-full flex flex-col items-center ">
      <div className="w-full md:w-1/2  flex flex-col   items-center justify-evenly mb-8">
        <PlatformBillEditableRow />
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4"
          disabled={!values.billItems.length}
          onClick={nextStepHandler}
        >
          {t("next").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default BillItems;
