import { RenderRoutes } from "../Config";
import { Route } from "react-router-dom";
import React from "react";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import CompanyManagementPage from "../Pages/CompanyPages/CompanyManagementPage";
import UsersManagementPage from "../Pages/CompanyPages/UsersManagementPage";
import AddFleetPage from "../Pages/CompanyPages/AddFleetPage";
import FleetListingPage from "../Pages/CompanyPages/FleetListingPage";
import AgenceManagementPage from "../Pages/CompanyPages/AgenceManagementPage";
import AddAgencePage from "../Pages/CompanyPages/AddAgencePage";
import AgenceListingPage from "../Pages/CompanyPages/AgenceListingPage";
import ClientsManagementPage from "../Pages/CompanyPages/ClientsManagementPage";
import AddClientPage from "../Pages/CompanyPages/AddClientPage";
import ClientsListingPage from "../Pages/CompanyPages/ClientsListingPage";
import ClientsLookupPage from "../Pages/CompanyPages/ClientsLookupPage";
import RentManagementPage from "../Pages/CompanyPages/RentManagementPage";
import AddNewRentPage from "../Pages/CompanyPages/AddNewRentPage";
import RentListingPage from "../Pages/CompanyPages/RentListingPage";
import InvoicesManagementPage from "../Pages/CompanyPages/InvoicesManagementPage";
import AddInvoicesPage from "../Pages/CompanyPages/AddInvoicesPage";
import BillsManagementPage from "../Pages/CompanyPages/BillsManagementPage";
import AddBillPage from "../Pages/CompanyPages/AddBillPage";
import BillsListingPage from "../Pages/CompanyPages/BillsListingPage";
import { FleetManagementPage } from "../Pages/CompanyPages/FleetManagementPage";
import { AddNewDriverPage } from "../Pages/CompanyPages/AddNewDriverPage";
import CompanyPlatformBillsPage from "../Pages/CompanyPages/BillsPlatformListingPage";
import { PointsTransactionsPage } from "../Pages/CompanyPages/PointsTransactionsPage";

export function CompanyManagementRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/company"
        element={
          <PrivateRoute>
            <CompanyManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users_management/users_management"
        element={
          <PrivateRoute>
            <UsersManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/fleet_management"
        element={
          <PrivateRoute>
            <FleetManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/fleet_management/add_fleet"
        element={
          <PrivateRoute>
            <AddFleetPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/fleet_management/fleet_listing"
        element={
          <PrivateRoute>
            <FleetListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/agence_management"
        element={
          <PrivateRoute>
            <AgenceManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/agence_management/add_agence"
        element={
          <PrivateRoute>
            <AddAgencePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/agence_management/agence_listing"
        element={
          <PrivateRoute>
            <AgenceListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients_management"
        element={
          <PrivateRoute>
            <ClientsManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients_management/add_client"
        element={
          <PrivateRoute>
            <AddClientPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients_management/clients_listing"
        element={
          <PrivateRoute>
            <ClientsListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients_management/clients_lookup"
        element={
          <PrivateRoute>
            <ClientsLookupPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/rent_management"
        element={
          <PrivateRoute>
            <RentManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/rent_management/add_newRent"
        element={
          <PrivateRoute>
            <AddNewRentPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/rent_management/add_driver"
        element={
          <PrivateRoute>
            <AddNewDriverPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/rent_management/rent_listing"
        element={
          <PrivateRoute>
            <RentListingPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/invoices_management/add_invoices"
        element={
          <PrivateRoute>
            <AddInvoicesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/invoices_management/invoices_listing"
        element={
          <PrivateRoute>
            <InvoicesManagementPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/bills_management"
        element={
          <PrivateRoute>
            <BillsManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills_management/add_bill"
        element={
          <PrivateRoute>
            <AddBillPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills_management/bills_listing"
        element={
          <PrivateRoute>
            <BillsListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/locasafe/bills_listing"
        element={
          <PrivateRoute>
            <CompanyPlatformBillsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/points_management/history"
        element={
          <PrivateRoute>
            <PointsTransactionsPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
