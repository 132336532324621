import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../../Layouts';
import PageLayout from '../../Layouts/PageLayout';

const RentManagementPage = () => {
   const { t } = useTranslation();

    return (
      <AppLayout>
        <PageLayout title={t('RentManagement')}>
          <div className='h-fit md:h-screen w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl'>

          </div>
        </PageLayout>
      </AppLayout>
    );
};

export default RentManagementPage;
