import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { PlatformInvoices } from "../Types";

export const usePlatformInvoicesData = (
  refreshInterval: number | undefined = 10000,
) => {
  const { t } = useTranslation();

  const [platformInvoices, setPlatformInvoices] = useState<
    PlatformInvoices[] | []
  >([]);

  const { data, isLoading, error, mutate } = useSwiftSWR("/platformInvoices", {
    refreshInterval,
  });

  useEffect(() => {
    const handleSearchData = () => {
      if (!isLoading && data) {
        setPlatformInvoices(data.message);
      }
    };

    const handleSearchError = () => {
      if (error) {
        toast.error(
          t(
            error?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleSearchData();
    handleSearchError();

    // eslint-disable-next-line
  }, [data, isLoading, error]);

  return { platformInvoices, isLoading, error, mutate };
};
