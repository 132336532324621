import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import AddFleetContent from "../../Components/Features/CompanyComponents/FleetManagement/addFleetContent";

const AddFleetPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_fleet")}>
        <div className="h-fit  w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <AddFleetContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default AddFleetPage;
