import React from "react";

export function ClientLookupContent() {
  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex flex-col items-center pb-8 rounded bg-neutral-100 overflow-hidden">
        <div className="flex gap-5 justify-between self-stretch p-2 px-5 w-full text-sm leading-4 bg-gray-200 rounded-none max-md:flex-wrap max-md:max-w-full">
          <div className="flex gap-4 my-auto tracking-widest uppercase text-neutral-500">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d903924d27c089a40fc355c07c5e545d4587d2c979b6eda9a5b84c9fb70c093?apiKey=47918fdd8d134febb6c66661b288dec3&"
              className="shrink-0 w-4 aspect-square"
            />
            <div>Search filter</div>
          </div>
        </div>
        <div className="flex flex-col w-full bg-amber-300 h-96"></div>
      </div>
    </div>
  );
}
