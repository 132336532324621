import { Button, Card, Col, Form as AntdForm, Input, Row } from "antd";
import { Field, FieldArray, useFormikContext } from "formik";
import { InvoiceInitialValues } from "./AddCompanyInvoiceContent";
import { useTranslation } from "react-i18next";

export function InvoiceEditableRow() {
  const { values } = useFormikContext<InvoiceInitialValues>();
  const { t } = useTranslation();

  return (
      <FieldArray name="invoiceItems">
        {({ push, remove }) => (
            <>
              {values["invoiceItems"].map((item, index) => (
                  <Card
                      key={index}
                      title={item.name}
                      style={{ marginBottom: "1rem" }}
                      extra={
                        <Button type="link" danger onClick={() => remove(index)}>
                          {t("REMOVE")}
                        </Button>
                      }
                  >
                    <Row gutter={16}>
                      <Col span={24}>
                        <AntdForm.Item label={t("NAME")}>
                          <Field name={`invoiceItems.${index}.name`} as={Input} />
                        </AntdForm.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={8}>
                        <AntdForm.Item label={t("QUANTITY")}>
                          <Field
                              name={`invoiceItems.${index}.quantity`}
                              as={Input}
                              style={{ width: "100%" }}
                          />
                        </AntdForm.Item>
                      </Col>
                      <Col span={8}>
                        <AntdForm.Item label={t("PRICE")}>
                          <Field
                              name={`invoiceItems.${index}.price`}
                              as={Input}
                              style={{ width: "100%" }}
                          />
                        </AntdForm.Item>
                      </Col>
                      <Col span={8}>
                        <AntdForm.Item label={t("TOTAL")}>
                          <Field
                              name={`invoiceItems.${index}.total`}
                              value={
                                  (values?.invoiceItems?.[index].price || 0) *
                                  (values?.invoiceItems?.[index].quantity || 0)
                              }
                              as={Input}
                              disabled
                              style={{ width: "100%" }}
                          />
                        </AntdForm.Item>
                      </Col>
                    </Row>
                  </Card>
              ))}

              <Button
                  type="dashed"
                  onClick={() =>
                      push({
                        name: "",
                        sku: "",
                        quantity: 0,
                        price: 0,
                        total: 0,
                        discountRate: 0,
                        taxRate: 1,
                        discountType: "FIXED",
                      })
                  }
                  style={{ width: "100%", marginTop: "1rem" }}
              >
                {t("ADD_ITEM")}
              </Button>
            </>
        )}
      </FieldArray>
  );
}
