import { Avatar, Popover } from "antd";
import { useSelector } from "react-redux";
import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import NavBarUserMenu from "./NavBarUserMenu";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useCompaniesStatsData } from "../../../Hooks/useCompaniesStatsData";
import TollIcon from "@mui/icons-material/Toll";

export function NavBar({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const user = useSelector((state: any) => state.authentication.user);
  const path = window.location.pathname;
  moment.locale(language);

  const [lastActive, setLastActive] = useState<string>(
    moment(new Date(user.lastActive), "YYYYMMDD").startOf("hour").fromNow(),
  );

  const { companiesStats, isLoading: isCompaniesStatsLoading } =
    useCompaniesStatsData(2000, user.accountType === "ENTREPRISE");

  useEffect(() => {
    const inter = setInterval(() => {
      setLastActive(
        moment(new Date(user.lastActive), "YYYYMMDD").startOf("hour").fromNow(),
      );
    }, 1000 * 60);

    return () => clearInterval(inter);
  }, [user]);

  const avatar =
    (user?.avatar &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
        user?.avatar) ||
    "";

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav className="w-full bg-white flex justify-between items-center px-5 py-3 border-b border-gray-200 ">
      <div className="h-full flex justify-center items-center">
        <div onClick={toggleCollapsed} className="mr-3 flex gap-4">
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          {user.accountType === "CONSULTANT" && (
            <img
              width={50}
              alt=""
              srcSet={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Tunisia.svg/800px-Flag_of_Tunisia.svg.png"
              }
            />
          )}
        </div>
      </div>

      <div className="flex gap-5 justify-center items-center">
        {user.accountType === "ENTREPRISE" &&
        path.includes("/rent_management") ? (
          <p className="text-2xl font-medium text-gray-900">
            <TollIcon className="mr-4" />
            {companiesStats ? String(companiesStats.point) : null}
          </p>
        ) : null}

        <Avatar
          size="large"
          alt={user?.name}
          src={avatar}
          crossOrigin={"anonymous"}
        />
        <div className="h-full hidden sm:flex sm:flex-col">
          <h5 className="font-medium text-sm">
            {user?.name || ""} {user?.lastName || ""}
          </h5>
          <h5 className="font-light text-xs">{user?.email || ""}</h5>
        </div>
        <Popover
          title={
            <p className="w-full flex justify-center items-center text-center">
              {t("last-active")} {lastActive}
            </p>
          }
          placement="bottomRight"
          content={() => <NavBarUserMenu />}
          overlayStyle={{
            width: "270px",
          }}
        >
          <CaretDownOutlined className="cursor-pointer" />
        </Popover>
      </div>
    </nav>
  );
}
