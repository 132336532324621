import React from "react";
import { useFormikContext } from "formik";
import { InvoiceInitialValues } from "./AddCompanyInvoiceContent";
import { CompanyInvoicePrintable } from "../../../../UI/Printables/CompanyInvoicePrintable";

function DisplayAddedInvoice() {
  const { values } = useFormikContext<InvoiceInitialValues>();

  if (!values.savedInvoice) {
    return null;
  }
  return <CompanyInvoicePrintable CompanyInvoice={values.savedInvoice} />;
}

export default DisplayAddedInvoice;
