import { AppLayout } from "../../Layouts";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { DashboardGreating } from "../../Components/Features/DashBoards/DashboardGreating";
import { DashboardInfoCards } from "../../Components/Features/DashBoards/DashboardInfoCards";
import { useSelector } from "react-redux";
import { RootReducer } from "../../Store/reducers";
import { Users } from "../../Types";
import { DashboardConsultationSearch } from "../../Components/Features/ConsultantComponents/DashboardConsultationSearch";
import { DashboardCompany } from "../../Components/Features/DashBoards/DashboardCompany";

export const DashboardPage = () => {
  const {
    i18n: { language },
  } = useTranslation();
  moment.locale(language);

  const user = useSelector(
    (state: RootReducer) => state?.authentication?.user,
  ) as Users;

  return (
    <AppLayout>
      <div className="w-full flex flex-col p-2 sm:p-9  ">
        <div className=" w-full flex flex-col   mt-5 md:mt-0 items-center">
          <DashboardGreating />
          {user?.accountType === "PLATFORM" && <DashboardInfoCards />}

          {user?.accountType === "CONSULTANT" && (
            <DashboardConsultationSearch />
          )}
          {user?.accountType === "ENTREPRISE" && <DashboardCompany />}
        </div>
      </div>
    </AppLayout>
  );
};
