import { useFormikContext } from "formik";
import { FormValues } from "./index";
import { Button, Card, Descriptions, Result } from "antd";
import { t } from "i18next";
import React from "react";
import moment from "moment";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import CopyToClipboard from "../../../../UI/CopyToClipboard";

export function RecapAddCompanyContent() {
  const { values, submitForm } = useFormikContext<FormValues>();
  const navigate = useNavigate();
  let { addedCompany, addedUser } = values;
  console.log(addedUser);

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <Result icon={<SmileOutlined />} title={t("company_created_success")} />

      <Card title={t("company_informations")} rootClassName="w-full md: w-4/5 ">
        {addedCompany && (
          <Descriptions bordered rootClassName="!mb-3" size="small" column={2}>
            {addedCompany.companyID && (
              <Descriptions.Item label={t("companyID")} span={2}>
                <CopyToClipboard input={addedCompany.companyID} />
              </Descriptions.Item>
            )}
            {addedCompany.companyType && (
              <Descriptions.Item label={t("typeRegistre")}>
                {addedCompany.companyType === "PP" ? t("pp") : t("pm")}
              </Descriptions.Item>
            )}
            {addedCompany.dateOfEst && (
              <Descriptions.Item label={t("dateOfEst")}>
                {moment(addedCompany.dateOfEst).format("DD/MM/YYYY")}
              </Descriptions.Item>
            )}
            {addedCompany.rneID && (
              <Descriptions.Item label={t("numRegistre")}>
                <CopyToClipboard input={addedCompany.rneID} />
              </Descriptions.Item>
            )}
            {addedCompany.VAT && (
              <Descriptions.Item label={t("matricule_fiscal")}>
                <CopyToClipboard input={addedCompany.VAT} />
              </Descriptions.Item>
            )}
            {addedCompany.rsLatin && (
              <Descriptions.Item label={t("nomCommercialFr")}>
                {addedCompany.rsLatin}
              </Descriptions.Item>
            )}
            {addedCompany.rsArabic && (
              <Descriptions.Item label={t("nomCommercialAr")}>
                {addedCompany.rsArabic}
              </Descriptions.Item>
            )}
            {addedCompany.activity && (
              <Descriptions.Item label={t("activity")}>
                {addedCompany.activity}
              </Descriptions.Item>
            )}
            {addedCompany.capital && (
              <Descriptions.Item label={t("capital")}>
                {addedCompany.capital}
              </Descriptions.Item>
            )}
            {addedCompany.email && (
              <Descriptions.Item label={t("auth_email")}>
                {addedCompany.email}
              </Descriptions.Item>
            )}
            {addedCompany.mainAddress && (
              <Descriptions.Item label={t("mainAddress")}>
                <CopyToClipboard input={addedCompany.mainAddress} />
              </Descriptions.Item>
            )}
            {addedCompany.secondAddress && (
              <Descriptions.Item label={t("secondAddress")}>
                {addedCompany.secondAddress}
              </Descriptions.Item>
            )}
            {addedCompany.isActive && (
              <Descriptions.Item label={t("activeCompany")}>
                {addedCompany.isActive ? t("yes") : t("no")}
              </Descriptions.Item>
            )}
            {addedCompany.createdAt && (
              <Descriptions.Item label={t("affiliation_date")}>
                {moment(addedCompany.createdAt).format("DD/MM/YYYY")}
              </Descriptions.Item>
            )}
            {addedCompany.createdBy.lastName && (
              <Descriptions.Item label={t("created_by")}>
                {`${addedCompany.createdBy.name} ${addedCompany.createdBy.lastName}`}
              </Descriptions.Item>
            )}
          </Descriptions>
        )}
      </Card>

      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="default"
          className="!h-9  !text-xs !px-12   "
          onClick={() => navigate("/")}
        >
          {t("locasafe_dashboard").toUpperCase()}
        </Button>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={submitForm}
        >
          {t("create_another_company").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}
