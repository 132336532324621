import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Tag } from "antd";
import moment from "moment";
import i18n from "i18next";
import { EyeFilled } from "@ant-design/icons";
import { PlatformInvoices } from "../../../../Types";
import CopyToClipboard from "../../../UI/CopyToClipboard";

export const CompanyPlatformBillsColumns: (
  t: any,
  chooseBillHandler?: any,
) => (ColumnGroupType<PlatformInvoices> | ColumnType<PlatformInvoices>)[] = (
  t,
  chooseBillHandler,
) => {
  const { language } = i18n;
  return [
    {
      title: t("date_of_creations"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
    },
    {
      title: t("ID"),
      dataIndex: "platformBillID",
      key: "platformBillID",
      render: (value) => (
        <div className="flex justify-center items-center">
          <CopyToClipboard input={value} showInput={false} showIcon={true} />
        </div>
      ),
    },

    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      width: 100,
      render: (value) => (
        <Tag color="volcano"> {`${Number(value).toFixed(3)} ${t("TND")}`}</Tag>
      ),
    },

    {
      title: t("dueDate"),
      dataIndex: "validUntil",
      key: "validUntil",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      title: t("client_name"),
      dataIndex: "company",
      key: "rsLatin",
      render: (value) => (language === "ar" ? value.rsArabic : value.rsLatin),
    },
    {
      title: t("matricule_fiscal"),
      dataIndex: "company",
      key: "VAT",
      render: (value) => value.VAT,
    },
    {
      title: t("comment"),
      dataIndex: "note",
      key: "note",
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value === "PENDING" ? (
          <Tag color="orange">{t("PENDING")}</Tag>
        ) : value === "VALIDATED" ? (
          <Tag color="green">{t("VALIDATED")}</Tag>
        ) : value === "REFUSED" ? (
          <Tag color="red">{t("REFUSED")}</Tag>
        ) : (
          <Tag color="purple">{t("ON_HOLD")}</Tag>
        );
      },
    },
    {
      title: t("actions"),
      dataIndex: "platformBillID",
      key: "actions",
      render: (value) => (
        <div className="flex justify-center items-center">
          <EyeFilled
            className="cursor-pointer "
            onClick={() => {
              chooseBillHandler(value);
            }}
          />
        </div>
      ),
    },
  ];
};
