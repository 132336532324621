import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Steps } from "antd";
import { Formik, FormikHelpers } from "formik";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GradingIcon from "@mui/icons-material/Grading";
import { SearchClient } from "./SearchClient";
import InvoiceItems from "./InvoiceItems";
import RecapInvoice from "./RecapInvoice";
import { Invoices } from "../../../../../Types";
import DisplayAddedInvoice from "./DisplayAddedInvoice";
import { AppstoreAddOutlined, FileDoneOutlined } from "@ant-design/icons";

export interface InvoiceInitialValues {
  savedInvoice?: Invoices | null;
  amount: number | null;
  note: string | null;
  footer: string | null;
  clientID: string | null;
  invoiceItems: InvoiceItems[] | [];
}

interface InvoiceItems {
  name: string | null;
  sku: string | null;
  quantity: number | null;
  price: number | null;
  total: number | null;
  discountRate: number | null;
  taxRate: number | null;
  discountType: "FIXED";
}

export function AddCompanyInvoiceContent() {
  const initialValues: InvoiceInitialValues = {
    clientID: null,
    amount: null,
    note: null,
    footer: null,
    invoiceItems: [],
  };

  const { t } = useTranslation();

  const [currentStepper, setCurrentStepper] = useState<number>(0);
  const asyncToast = useRef<any>();
  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      asyncToast.current = toast.loading(t("CREATE_INVOICE_ONGOING_MESSAGE"));
      const data = {
        ...values,
        amount: values.invoiceItems.reduce(
          (acc: number, value: any) =>
            acc + Number(value.price * value.quantity),
          0,
        ),
        invoiceItems: values.invoiceItems.map((item: any) => {
          return {
            ...item,
            price: Number(item.price),
            total: Number(item.price * item.quantity),
            quantity: Number(item.quantity),
          };
        }),
      };

      const savedInvoice = await Axios.post("companyInvoices", data).then(
        (res) => res.data.message,
      );

      toast.update(asyncToast.current, {
        render: t("CREATE_INVOICE_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      await formikHelpers.setFieldValue("savedInvoice", savedInvoice);

      setCurrentStepper((prevState) => prevState + 1);
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const stepperItems = [
    {
      title: t("clients_lookup"),
      icon: <PublishedWithChangesIcon />,
    },
    {
      title: t("items"),
      icon: <AppstoreAddOutlined />,
    },
    {
      title: t("recap"),
      icon: <GradingIcon />,
    },
    {
      title: t("invoices"),
      icon: <FileDoneOutlined />,
    },
  ];

  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <div className=" w-full ">
        <Steps
          size="small"
          className="site-navigation-steps"
          current={currentStepper}
          items={stepperItems}
        />
      </div>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSubmitHandler(values, formikHelpers);
        }}
      >
        {() => {
          switch (currentStepper) {
            case 0:
              return <SearchClient setCurrentStepper={setCurrentStepper} />;
            case 1:
              return <InvoiceItems setCurrentStepper={setCurrentStepper} />;
            case 2:
              return <RecapInvoice setCurrentStepper={setCurrentStepper} />;
            case 3:
              return <DisplayAddedInvoice />;
          }
        }}
      </Formik>
    </div>
  );
}
