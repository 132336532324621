import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Agences } from "../../../../../Types";

export const AgencesListingColumns: (
  t: any,
) => (ColumnGroupType<Agences> | ColumnType<Agences>)[] = (t) => [
  {
    title: t("agenceCode"),
    dataIndex: "agenceCode",
    key: "agenceCode",
  },
  {
    title: t("agenceName"),
    dataIndex: "agenceName",
    key: "agenceName",
  },
  {
    title: t("agenceAddress"),
    dataIndex: "agenceAddress",
    key: "agenceAddress",
  },
  {
    title: t("isDeleted"),
    dataIndex: "isDeleted",
    key: "isDeleted",
  },
  {
    title: t("AgenceManagers"),
    dataIndex: "AgenceManagers",
    key: "AgenceManagers",
    render: (value) => value.length,
  },
  {
    title: t("rent_contracts"),
    dataIndex: "RentContract",
    key: "RentContract",
    render: (value) => value.length,
  },
  {
    title: t("invoices"),
    dataIndex: "Invoices",
    key: "Invoices",
    render: (value) => value.length,
  },
  {
    title: t("bills"),
    dataIndex: "Bills",
    key: "Bills",
    render: (value) => value.length,
  },
];
