import React, { useState } from "react";
import { Button, Table } from "antd";
import { ConsultantUserListingColumns } from "./ConsultantUserListingColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddUserModal from "../../../../UI/addUserModal";
import { useConsultantUsersData } from "../../../../../Hooks/useConsultantUsersData";

function ConsultantUserListingContent() {
  const { consultantUsers, isLoading, mutate } = useConsultantUsersData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.authentication.user);

  const columns = ConsultantUserListingColumns(t, user.userID, mutate);

  return (
    <div className="w-full  overflow-scroll p-10">
      <AddUserModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        mutate={mutate}
      />
      <div className="w-full flex gap-4 flex-col md:flex-row mb-8 justify-end">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t("create_user").toUpperCase()}
        </Button>
      </div>
      <Table
        rowKey="userID"
        size="small"
        loading={isLoading}
        dataSource={(consultantUsers && consultantUsers) || []}
        columns={columns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}

export default ConsultantUserListingContent;
