import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";

export const usePointsTransactionsData = (
  refreshInterval: number | undefined = 10000,
) => {
  const { t } = useTranslation();

  const [pointsTransactions, setPointsTransactions] = useState<any[] | []>([]);
  const [points, setPoints] = useState<any[] | []>([]);

  const { data, isLoading, error, mutate } = useSwiftSWR("/companyPoints/", {
    refreshInterval,
  });

  useEffect(() => {
    const handleSearchData = () => {
      if (!isLoading && data) {
        setPointsTransactions(data.message.transactions);
        setPoints(data.message.currentPoint.currentPoint);
      }
    };

    const handleSearchError = () => {
      if (error) {
        toast.error(
          t(
            error?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleSearchData();
    handleSearchError();

    // eslint-disable-next-line
  }, [data, isLoading, error]);

  return { points, pointsTransactions, isLoading, error, mutate };
};
