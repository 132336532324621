import React, { Dispatch, Fragment, SetStateAction, useMemo } from "react";
import { useFormikContext } from "formik";
import { InvoiceInitialValues } from "./AddCompanyInvoiceContent";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import { Button, List, QRCode } from "antd";
import { t } from "i18next";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

function RecapInvoice({ setCurrentStepper }: propsT) {
  const { clients } = useClientsData();
  const { values, submitForm } = useFormikContext<InvoiceInitialValues>();

  const client = useMemo(() => {
    return clients.filter((clt) => clt.clientID === values.clientID)[0];
  }, [values.clientID, clients]);

  return (
    <div className="w-full flex flex-col items-center ">
      <div className="w-full md:w-2/3  flex flex-col   items-center justify-evenly mb-8">
        {client && (
          <List bordered size="default" rootClassName="w-full md:w-3/4">
            <List.Item>
              <List.Item.Meta
                avatar={
                  <QRCode
                    value={client?.clientID || ""}
                    size={80}
                    bordered={false}
                  />
                }
                title={
                  <span>
                    {client.denomination?.toUpperCase() ||
                      client.fullName?.toUpperCase()}
                  </span>
                }
                description={
                  <div className="w-full flex flex-col gap-2">
                    <span>{client.address}</span>
                    <span>{client.email}</span>
                    <span>{client.phone}</span>
                  </div>
                }
              />
            </List.Item>
          </List>
        )}
        <div className="mt-6">
          <div className="border border-gray-200 p-4 rounded-lg space-y-4 dark:border-neutral-700">
            <div className="hidden sm:grid sm:grid-cols-5">
              <div className="sm:col-span-2 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                Description
              </div>
              <div className="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                Quantité
              </div>
              <div className="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                Prix Unitaire TTC
              </div>
              <div className="text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                Total TTC
              </div>
            </div>

            <div className="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>
            {values.invoiceItems.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <div className="grid grid-cols-3 sm:grid-cols-5 gap-2">
                    <div className="col-span-full sm:col-span-2">
                      <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        Description
                      </h5>
                      <p className="font-medium text-gray-800 dark:text-neutral-200">
                        {item.name}
                      </p>
                    </div>
                    <div>
                      <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        Quantité
                      </h5>
                      <p className="text-gray-800 dark:text-neutral-200">
                        {item.quantity}
                      </p>
                    </div>
                    <div>
                      <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        Prix Unitaire
                      </h5>
                      <p className="text-gray-800 dark:text-neutral-200">
                        {item.price} DNT
                      </p>
                    </div>

                    <div>
                      <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        Amount
                      </h5>
                      <p className="sm:text-end text-gray-800 dark:text-neutral-200">
                        {item.quantity * item.price} DNT
                      </p>
                    </div>
                  </div>
                  <div className="sm:hidden border-b border-gray-200 dark:border-neutral-700"></div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex gap-4 flex-col md:flex-row mt-4">
        <Button
          type="default"
          className="!h-9  !text-xs !px-12   "
          onClick={() => setCurrentStepper((prevState) => prevState - 1)}
        >
          {t("back").toUpperCase()}
        </Button>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={submitForm}
        >
          {t("add_invoices").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default RecapInvoice;
