import React from "react";
import { useFormikContext } from "formik";
import { BillInitialValues } from "./AddCompanyBillContent";
import { CompanyBillPrintable } from "../../../../UI/Printables/CompanyBillPrintable";

function DisplayAddedBill() {
  const { values } = useFormikContext<BillInitialValues>();

  if (!values.savedBill) {
    return null;
  }
  return <CompanyBillPrintable CompanyBill={values.savedBill} />;
}

export default DisplayAddedBill;
