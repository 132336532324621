import React, { useMemo, useRef, useState } from "react";
import { useCompaniesData } from "../../../../../Hooks";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { CompanyListingTableColumns } from "./CompanyListingTableColumns";
import Search from "antd/es/input/Search";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { pointsTransactionsColumns } from "../../../CompanyComponents/PointsTransactions/PointsTransactionsColumns";
import CompanyAddPointsModal from "./CompanyAddPointsModal";

export function CompanyListingContent() {
  const {
    isLoading: isCompaniesDataLoading,
    companies,
    mutate,
  } = useCompaniesData();

  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const dataToDisplay = useMemo(() => {
    return search
      ? companies.filter((comp) => {
          return Boolean(
            comp.companyID.toLowerCase().includes(search.toLowerCase()) ||
              comp.rsArabic?.toLowerCase().includes(search.toLowerCase()) ||
              comp.rsLatin?.toLowerCase().includes(search.toLowerCase()) ||
              comp.VAT?.toLowerCase().includes(search.toLowerCase()) ||
              comp.rneID?.toLowerCase().includes(search.toLowerCase()) ||
              comp.email?.toLowerCase().includes(search.toLowerCase()) ||
              comp.mainAdress?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : companies;
  }, [companies, search]);

  const asyncToast = useRef<any>();

  const userCompanyStatusHandler = async (
    companyID: string,
    status: boolean,
  ) => {
    try {
      const path = status
        ? `/platform/company/activate/${companyID}`
        : `/platform/company/suspend/${companyID}`;
      asyncToast.current = toast.loading(t("COMPANY_STATUS_ONGOING_MESSAGE"));
      const response = await Axios.put(path).then((res) => {
        mutate();
        return res.data.message;
      });

      toast.update(asyncToast.current, {
        render: t(
          response["RESPONSE_CODE"] || "COMPANY_CHANGE_STATUS_SUCCESS_MESSAGE",
        ),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const columns = CompanyListingTableColumns(t, userCompanyStatusHandler);
  const expandedColumns = pointsTransactionsColumns(t);
  expandedColumns.pop();

  const ExpandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col" key={record.companyID}>
        <CompanyAddPointsModal record={record} />
        <Table
          columns={expandedColumns}
          dataSource={record.CompanyPointTransaction}
          direction="rtl"
        />
      </div>
    );
  };
  return (
    <div className="w-full overflow-scroll p-10 !relative">
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isCompaniesDataLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="companyID"
        size="small"
        expandable={{
          expandedRowRender: ExpandedRowRender,
        }}
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={true}
      />
    </div>
  );
}
