import React, { useMemo, useState } from "react";
import { Modal, Table } from "antd";
import { CompanyBillsColumns } from "./CompanyBillsColumns";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { useCompanyBillsData } from "../../../../../Hooks/useCompanyBillsData";
import { CompanyBillPrintable } from "../../../../UI/Printables/CompanyBillPrintable";
import { useMediaQuery } from "@mui/material";

export function CompanyBillsListing() {
  const { t } = useTranslation();

  const { isLoading, bills } = useCompanyBillsData();
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState<null | string>(null);

  const chooseBillHandler = (BillID: string | null) => {
    setOpen(true);
    setSelectedBill(BillID);
  };
  const columns = CompanyBillsColumns(t, chooseBillHandler);

  const [search, setSearch] = useState("");
  const dataToDisplay = useMemo(() => {
    return search
      ? bills.filter((bill) => {
          return Boolean(
            bill.billID?.toLowerCase().includes(search.toLowerCase()) ||
              bill.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.vatNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.idNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()),
          );
        })
      : bills;
  }, [bills, search]);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
        >
          <CompanyBillPrintable
            CompanyBill={
              bills.filter((bill) => bill.billID === selectedBill)[0]
            }
          />
        </Modal>
      )}
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="billID"
        size="small"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={true}
      />
    </div>
  );
}
