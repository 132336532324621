import React, { useState } from "react";
import { Divider, Steps } from "antd";
import { useTranslation } from "react-i18next";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GradingIcon from "@mui/icons-material/Grading";
import { Formik } from "formik";
import CompanySearchContent from "./CompanySearchContent";
import CompanyInfoContent from "./CompanyInfoContent";
import AssignUserToCompany from "./AssignUserToCompany";
import { Company, Users } from "../../../../../Types";
import { RecapAddCompanyContent } from "./RecapAddCompanyContent";

export interface FormValues {
  addedCompany: Company | null;
  user: {
    name: null;
    lastName: null;
    email: null;
    phone: null;
    accountType: "PLATFORM" | "ENTREPRISE" | "CONSULTANT";
    companyID: null;
  };
  addedUser: Users | null;
  company: {
    rneID: string | null;
    companyType: "PM" | "PP" | null;
    rsLatin: string | null;
    rsArabic: string | null;
    email: string | null;
    dateOfEst: Date | null;
    activity: string | null;
    mainAddress: string | null;
    secondAddress: string | null;
    isActive: boolean | null;
    capital: number | null;
    VAT: string | null;
    initPoint?: Number | null;
  };
}

export function AddCompanyContent() {
  const { t } = useTranslation();

  const [currentStepper, setCurrentStepper] = useState<number>(0);

  const stepperItems = [
    {
      title: t("company_search"),
      icon: <PublishedWithChangesIcon />,
    },
    {
      title: t("company_informations"),
      icon: <AddBusinessIcon />,
    },
    {
      title: t("assign_user_company"),
      icon: <GroupAddIcon />,
    },
    {
      title: t("recap"),
      icon: <GradingIcon />,
    },
  ];

  const initialValues: FormValues = {
    addedCompany: null,
    addedUser: null,
    company: {
      rneID: null,
      companyType: null,
      rsLatin: null,
      rsArabic: null,
      email: null,
      dateOfEst: null,
      activity: null,
      mainAddress: null,
      secondAddress: null,
      isActive: null,
      capital: null,
      VAT: null,
    },
    user: {
      name: null,
      lastName: null,
      email: null,
      phone: null,
      accountType: "ENTREPRISE",
      companyID: null,
    },
  };

  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <div className=" w-full ">
        <Steps
          size="small"
          className="site-navigation-steps"
          current={currentStepper}
          items={stepperItems}
        />
      </div>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(_, { resetForm }) => {
          resetForm();
          setCurrentStepper(0);
        }}
      >
        {() => {
          switch (currentStepper) {
            case 0:
              return (
                <CompanySearchContent setCurrentStepper={setCurrentStepper} />
              );
            case 1:
              return (
                <CompanyInfoContent setCurrentStepper={setCurrentStepper} />
              );
            case 2:
              return (
                <AssignUserToCompany setCurrentStepper={setCurrentStepper} />
              );
            case 3:
              return <RecapAddCompanyContent />;
          }
        }}
      </Formik>
    </div>
  );
}
