import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import RneLookUpContent from "../../Components/Features/PlatformComponents/RneLookup/RneLookUpContent";

export function RneLookUPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("rne_lookup")}>
        <div className="h-fit w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl  ">
          <RneLookUpContent />
        </div>
        <div className="flex flex-col mt-8 w-full items-center ">
          <p className="max-w-[400px] text-xs text-center mb-8 text-gray-600">
            {t("rne_disclaimer")}
          </p>
          <img
            width={200}
            alt="RNE LOGO"
            srcSet={
              "https://www.registre-entreprises.tn/rne-public/assets/images/logo.svg"
            }
          />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
