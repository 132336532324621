import React from "react";
import { Image, Table } from "antd";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import {
  ClientsExpendableListingColumns,
  ClientsListingColumns,
} from "./ClientsListingColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function ClientsListingContent() {
  const { isLoading, clients } = useClientsData();

  const { t } = useTranslation();
  const columns = ClientsListingColumns(t);
  const user = useSelector((state: any) => state.authentication.user);

  if (!user.AgencesUsers.length) {
    columns.push({
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      render: (value) => {
        return value ? value.agenceName : "-";
      },
    });
  }
  const expandedColumns = ClientsExpendableListingColumns(t);
  const expandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col">
        <Table
          columns={expandedColumns}
          dataSource={[record]}
          pagination={false}
          direction="rtl"
        />
        <div className="my-6 flex justify-evenly">
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {record?.idRecto?.name && (
              <Image
                width={400}
                height={250}
                title={"ID RECTO"}
                src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record.idRecto.name}`}
              />
            )}
            {record?.idVerso?.name && (
              <Image
                width={400}
                height={250}
                title={"ID VERSO"}
                src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record.idVerso.name}`}
              />
            )}
          </Image.PreviewGroup>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full  overflow-scroll p-10">
      <Table
        rowKey="clientID"
        size="small"
        columns={columns}
        loading={isLoading}
        dataSource={clients}
        expandable={{
          expandedRowRender,
        }}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}

export default ClientsListingContent;
