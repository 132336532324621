import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompaniesData } from "../../../../Hooks";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import { Descriptions, Empty, Radio, Spin } from "antd";
import Search from "antd/es/input/Search";

function RneLookUpContent() {
  const { t } = useTranslation();
  const { isLoading: isCompaniesDataLoading } = useCompaniesData();
  const [rneSearch, setRneSearch] = useState<any | null>(null);
  const [rneAdvancedSearch, setRneAdvancedSearch] = useState<any | null>(null);

  const [type, setType] = useState<string>("pm");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchCompanyHandler = async (vat: string) => {
    try {
      if (vat === "") {
        setRneAdvancedSearch(null);
        setRneSearch(null);
        return;
      }

      setIsLoading(true);
      setRneAdvancedSearch(null);
      setRneSearch(null);
      const rneResult = await Axios.get(
        `/platform/rne/lookup?idUnique=${vat}&type=${type}`,
      ).then((res) => res.data);

      if (rneResult?.message?.count === 0) {
        setRneSearch([]);
      } else {
        const rneAdvancedResult = await Axios.get(
          `https://www.registre-entreprises.tn/rne-api/public/registres/${type}/${rneResult?.message?.registres[0].numRegistre}`,
        ).then((res) => res.data);
        setRneAdvancedSearch(rneAdvancedResult);
        setRneSearch(rneResult?.message?.registres[0]);
      }
      setIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const renderDescriptionItems = (data: any) => {
    return Object.keys(data).map((key, index) => {
      if (
        data[key] &&
        typeof data[key] === "object" &&
        !Array.isArray(data[key])
      ) {
        return (
          <Descriptions.Item label={key} key={index}>
            <Descriptions size="small" column={1}>
              {renderDescriptionItems(data[key])}
            </Descriptions>
          </Descriptions.Item>
        );
      } else if (data[key]) {
        return (
          <Descriptions.Item label={key} key={index}>
            {data[key]}
          </Descriptions.Item>
        );
      }
      return null;
    });
  };

  return (
    <div className="w-full flex flex-col items-center p-8 ">
      <div className="my-8">
        <Radio.Group
          defaultValue={type}
          buttonStyle="outline"
          onChange={(value) => {
            setType(value.target.value);
          }}
        >
          <Radio.Button value="pm">{t("pm")}</Radio.Button>
          <Radio.Button value="pp">{t("pp")}</Radio.Button>
        </Radio.Group>
      </div>
      <div className="w-full md:w-1/2  flex flex-col md:flex-row items-center justify-evenly mb-8">
        <Search
          placeholder={`${t("matricule_fiscal")} (0000000X)`}
          allowClear
          disabled={isCompaniesDataLoading}
          onSearch={(mf) => searchCompanyHandler(mf)}
          style={{ width: 304 }}
        />
      </div>

      {isLoading || isCompaniesDataLoading ? <Spin className="my-16" /> : null}

      {Array.isArray(rneSearch) && !rneSearch.length ? (
        <Empty description={t("no_data")} />
      ) : null}

      {rneSearch && (
        <Descriptions
          bordered
          rootClassName="!mb-6 !max-w-2/3"
          size="small"
          column={2}
        >
          {Object.keys(rneSearch).map((key, index) => {
            return rneSearch[key] ? (
              <Descriptions.Item label={t(key)} key={index}>
                {rneSearch[key]}
              </Descriptions.Item>
            ) : null;
          })}
        </Descriptions>
      )}
      {rneAdvancedSearch && (
        <>
          <Descriptions
            bordered
            rootClassName="!mb-6 !max-w-2/3"
            size="small"
            column={2}
          >
            <Descriptions.Item span={1} label="Registry Number">
              {rneAdvancedSearch.numRegistre || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Registry Type">
              {rneAdvancedSearch.typeRegistre || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Registry Category">
              {rneAdvancedSearch.categorieRegistre || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Unique ID">
              {rneAdvancedSearch.idUnique || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Registry Status (AR)">
              {rneAdvancedSearch.etatRegistreAr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Registry Status (FR)">
              {rneAdvancedSearch.etatRegistreFr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Deposit Number">
              {rneAdvancedSearch.numDepot || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Start Date of Activity">
              {rneAdvancedSearch.dateDebutActivite || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Registration Date">
              {rneAdvancedSearch.dateEnregistrement || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Publication Date">
              {rneAdvancedSearch.datePublication || ""}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Legal Situation (AR)">
              {rneAdvancedSearch.situationJuridiqueAr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Street Address (AR)">
              {rneAdvancedSearch.rueSiegeAr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Postal Code">
              {rneAdvancedSearch.codePostalSiege || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="City (AR)">
              {rneAdvancedSearch.villeSiegeAr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="City (FR)">
              {rneAdvancedSearch.villeSiegeFr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Association Object">
              {rneAdvancedSearch.objectAssociation || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Association Objectives">
              {rneAdvancedSearch.objectifsAssociation || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Nature of Association">
              {rneAdvancedSearch.natureAssociation || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Association Name (FR)">
              {rneAdvancedSearch.nomAssociationFr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Association Name (AR)">
              {rneAdvancedSearch.nomAssociationAr || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Jurisdiction (AR)">
              {rneAdvancedSearch.juridictionAr || ""}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions size="small">
            <Descriptions.Item span={1} label="Months in Fiscal Default">
              {rneAdvancedSearch?.situationFiscale?.nbMoisDefautFiscal || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Last Fiscal Update">
              {rneAdvancedSearch?.situationFiscale
                ?.dateMiseAJourSituationFiscale || ""}
            </Descriptions.Item>
            <Descriptions.Item span={1} label="Notification Date">
              {rneAdvancedSearch?.situationFiscale?.dateNotification || ""}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}

export default RneLookUpContent;
