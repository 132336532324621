import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { Button } from "antd";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router";
import { RentPrintableContract } from "../../../../UI/Printables/RentPrintableContract";

export function RecapRentContract() {
  const { values } = useFormikContext<RentInitialValues>();
  const { addedContract } = values;
  const navigate = useNavigate();

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <RentPrintableContract contract={addedContract} />

      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="default"
          className="!h-9 !text-xs !px-12 "
          onClick={() => navigate("/")}
        >
          {t("locasafe_dashboard").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}
