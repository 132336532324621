import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../../Layouts";
import PageLayout from "../../../Layouts/PageLayout";
import MyRentSearchHistoryContent from "../../../Components/Features/ConsultantComponents/SearchHistory/myRentSearchHistoryContent";

export function SearchHistoryPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("search_history")}>
        <div className="h-fit md:h-screen w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl ">
          <MyRentSearchHistoryContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
