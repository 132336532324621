import React from "react";
import { useFormikContext } from "formik";
import { PlatformBillInitialValues } from "./AddPlatformBillContent";
import { PlatformBillPrintable } from "../../../../UI/Printables/PlatformBillPrintable";

function DisplayAddedBill() {
  const { values } = useFormikContext<PlatformBillInitialValues>();

  if (!values.savedBill) {
    return null;
  }
  return <PlatformBillPrintable PlatformBill={values.savedBill} />;
}

export default DisplayAddedBill;
