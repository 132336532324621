import React, { useMemo, useState } from "react";
import { Table, Tag, Tooltip } from "antd";
import { fleetListingColumns } from "./FleetListingColumns";
import { useFleetData } from "../../../../../Hooks";
import Search from "antd/es/input/Search";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

function FleetListingContent() {
  const { fleet, isLoading } = useFleetData();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const dataToDisplay = useMemo(() => {
    return search
      ? fleet &&
          fleet.filter((car) => {
            return Boolean(
              car.registrationNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
                car.modal?.toLowerCase().includes(search.toLowerCase()),
            );
          })
      : fleet;
  }, [fleet, search]);

  const availableTodayCount = useMemo(() => {
    return fleet
      ? fleet.filter((car) => {
          return Boolean(
            car.RentContract.every(
              (rent) =>
                moment(rent.startDate).diff(new Date()) > 0 ||
                moment(new Date()).diff(rent.endDate) < 0,
            ),
          );
        }).length
      : 0;
  }, [fleet]);

  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex gap-4 mb-4 justify-between">
        <div className="flex gap-4 justify-center items-center">
          <Search
            placeholder={t("search")}
            allowClear
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
          <Tooltip title={t("plate_search_info")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className="flex gap-4">
          {fleet?.length && (
            <div>
              <Tag color="cyan">
                {t("fleet_count")} :{fleet.length}
              </Tag>
            </div>
          )}
          {fleet?.length && (
            <div>
              <Tag color="cyan">
                {t("fleet_available_today")} :{availableTodayCount}
              </Tag>
            </div>
          )}
        </div>
      </div>
      <Table
        rowKey="fleetID"
        size="small"
        loading={isLoading}
        dataSource={(dataToDisplay && dataToDisplay) || []}
        columns={fleetListingColumns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}

export default FleetListingContent;
