import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import CompanyUserListingContent from "../../Components/Features/CompanyComponents/UsersManagement/UsersListing/CompanyUserListingContent";

const UsersManagementPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("users_management")}>
        <div className="h-fit md:h-screen w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <CompanyUserListingContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default UsersManagementPage;
