import React from "react";
import { useSearchHistoryConsultantData } from "../../../../Hooks";
import { searchHistoryColumns } from "./searchHistoryColumns";
import { Table } from "antd";

function MyRentSearchHistoryContent() {
  const { isLoading, searchHistory } = useSearchHistoryConsultantData();

  return (
    <div className="w-full  overflow-scroll p-10">
      <Table
        rowKey="rentSearchHistoryID"
        size="small"
        loading={isLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={searchHistoryColumns}
        dataSource={searchHistory}
        bordered={true}
      />
    </div>
  );
}

export default MyRentSearchHistoryContent;
