import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Steps } from "antd";
import { Formik, FormikHelpers } from "formik";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GradingIcon from "@mui/icons-material/Grading";
import { SearchClient } from "./SearchClient";
import BillItems from "./BillItems";
import RecapBill from "./RecapBill";
import { Bills } from "../../../../../Types";
import DisplayAddedBill from "./DisplayAddedBill";
import { AppstoreAddOutlined, FileDoneOutlined } from "@ant-design/icons";

export interface BillInitialValues {
  savedBill?: Bills | null;
  amount: number | null;
  note: string | null;
  footer: string | null;
  clientID: string | null;
  billsItems: BillItem[] | [];
}

interface BillItem {
  name: string | null;
  sku: string | null;
  quantity: number | null;
  price: number | null;
  total: number | null;
  discountRate: number | null;
  taxRate: number | null;
  discountType: "FIXED";
}

export function AddCompanyBillContent() {
  const initialValues: BillInitialValues = {
    clientID: null,
    amount: null,
    note: null,
    footer: null,
    billsItems: [],
  };

  const { t } = useTranslation();
  const asyncToast = useRef<any>();
  const [currentStepper, setCurrentStepper] = useState<number>(0);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      asyncToast.current = toast.loading(t("CREATE_BILL_ONGOING_MESSAGE"));

      const data = {
        ...values,
        amount: values.billsItems.reduce(
          (acc: number, value: any) =>
            acc + Number(value.price * value.quantity),
          0,
        ),
        billsItems: values.billsItems.map((item: any) => {
          return {
            ...item,
            price: Number(item.price),
            total: Number(item.price * item.quantity),
            quantity: Number(item.quantity),
          };
        }),
      };

      const savedBill = await Axios.post("companyBills", data).then(
        (res) => res.data.message,
      );

      await formikHelpers.setFieldValue("savedBill", savedBill);
      toast.update(asyncToast.current, {
        render: t("CREATE_BILL_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setCurrentStepper((prevState) => prevState + 1);
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const stepperItems = [
    {
      title: t("clients_lookup"),
      icon: <PublishedWithChangesIcon />,
    },
    {
      title: t("items"),
      icon: <AppstoreAddOutlined />,
    },
    {
      title: t("recap"),
      icon: <GradingIcon />,
    },
    {
      title: t("bills"),
      icon: <FileDoneOutlined />,
    },
  ];

  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <div className=" w-full ">
        <Steps
          size="small"
          className="site-navigation-steps"
          current={currentStepper}
          items={stepperItems}
        />
      </div>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSubmitHandler(values, formikHelpers);
        }}
      >
        {() => {
          switch (currentStepper) {
            case 0:
              return <SearchClient setCurrentStepper={setCurrentStepper} />;
            case 1:
              return <BillItems setCurrentStepper={setCurrentStepper} />;
            case 2:
              return <RecapBill setCurrentStepper={setCurrentStepper} />;
            case 3:
              return <DisplayAddedBill />;
          }
        }}
      </Formik>
    </div>
  );
}
