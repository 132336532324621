import { MenuProps } from "antd";
import React from "react";
import {
  AppstoreFilled,
  FundOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Users } from "../../../Types";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Groups3Icon from "@mui/icons-material/Groups3";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DescriptionIcon from "@mui/icons-material/Description";
import TollIcon from "@mui/icons-material/Toll";

export type MenuItem = Required<MenuProps>["items"][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const sideBarMenu: (t: any, user: Users | null) => MenuItem[] = (
  t,
  user,
) => {
  let itemsList: MenuItem[] = [
    getItem(t("locasafe_dashboard"), "/", <AppstoreFilled />),
  ];

  if (!user) {
    return [];
  }

  const PLATFORM_MENU = [
    getItem(
      t("users_management"),
      "/users_management/users_management",
      <PeopleAltIcon />,
    ),
    getItem(t("companies_management"), "", <BusinessIcon />, [
      getItem(t("add_company"), "/locasafe_management/add_company"),
      getItem(t("company_listing"), "/locasafe_management/company_listing"),
    ]),
    getItem(t("invoices_management"), "/invoices_management", <ReceiptIcon />, [
      // getItem(t("invoices_dashboard"), "/invoices_management"),
      getItem(t("add_invoices"), "/invoices_management/add_invoices"),
      getItem(t("invoices_listing"), "/invoices_management/invoices_listing"),
    ]),
    getItem(t("bills_management"), "/bills_management/", <DescriptionIcon />, [
      getItem(t("add_bill"), "/bills_management/add_bill"),
      getItem(t("bills_listing"), "/bills_management/bills_listing"),
      //   getItem(t("bills_payements"), "/bills_management/bills_payements"),
    ]),
    getItem(
      t("subscription_management"),
      "/subscription_management",
      <ReceiptLongIcon />,
      [
        getItem(
          t("subscription_prices"),
          "/subscription_management/subscription_prices",
        ),

        /*   getItem(
            t("subscription_listing"),
            "/subscription_management/subscription_listing",
        ),
         getItem(
                    t("subscription_payements"),
                    "/subscription_management/subscription_payements",

                    <FundOutlined />,
                  ),*/
      ],
    ),
    getItem(t("rne_lookup"), "/rne_lookup", <MonitorOutlined />),
  ];

  const ENTREPRISE_MENU = [
    getItem(t("company_management"), "/company", <GroupWorkIcon />, [
      getItem(t("company_management"), "/company"),
      user.AgencesUsers.length
        ? null
        : getItem(t("locasafe_bills"), "/locasafe/bills_listing"),
    ]),
    getItem(
      t("users_management"),
      "/users_management/users_management",
      <PeopleAltIcon />,
    ),
    getItem(t("fleet_management"), "/fleet_management", <DirectionsCarIcon />, [
      getItem(t("add_fleet"), "/fleet_management/add_fleet"),
      getItem(t("fleet_listing"), "/fleet_management/fleet_listing"),
    ]),
    user.AgencesUsers.length
      ? null
      : getItem(
          t("agence_management"),
          "/agence_management",
          <BusinessIcon />,
          [
            getItem(t("add_agence"), "/agence_management/add_agence"),
            getItem(t("agence_listing"), "/agence_management/agence_listing"),
          ],
        ),
    getItem(t("clients_management"), "/clients_management", <Groups3Icon />, [
      getItem(t("add_client"), "/clients_management/add_client"),
      getItem(t("clients_listing"), "/clients_management/clients_listing"),
      //getItem(t("clients_lookup"), "/clients_management/clients_lookup"),
    ]),
    getItem(t("rent_management"), "/rent_management", <ReceiptLongIcon />, [
      getItem(t("add_driver"), "/rent_management/add_driver"),
      getItem(t("add_newRent"), "/rent_management/add_newRent"),
      getItem(t("rent_listing"), "/rent_management/rent_listing"),
    ]),
    getItem(t("invoices_management"), "/invoices_management", <ReceiptIcon />, [
      getItem(t("add_invoices"), "/invoices_management/add_invoices"),
      getItem(t("invoices_listing"), "/invoices_management/invoices_listing"),
    ]),
    getItem(t("bills_management"), "/bills_management", <DescriptionIcon />, [
      getItem(t("add_bill"), "/bills_management/add_bill"),
      getItem(t("bills_listing"), "/bills_management/bills_listing"),
    ]),
    getItem(t("points_management"), "/points_management", <TollIcon />, [
      getItem(t("transaction_history"), "/points_management/history"),
    ]),
  ];
  const CONSULTANT_MENU = user?.isConsultantAdmin
    ? [
        getItem(t("consultant_management"), "/consultant/", <GroupWorkIcon />, [
          getItem(t("search_history"), "/consultant/history", <FundOutlined />),
          getItem(
            t("users_management"),
            "/users_management/users_management",
            <PeopleAltIcon />,
          ),
        ]),
      ]
    : [];

  if (user.accountType === "PLATFORM") {
    itemsList = [...itemsList, ...PLATFORM_MENU];
  }

  if (user.accountType === "CONSULTANT") {
    itemsList = [...itemsList, ...CONSULTANT_MENU];
  }

  if (user.accountType === "ENTREPRISE") {
    itemsList = [...itemsList, ...ENTREPRISE_MENU];
  }

  return itemsList;
};
