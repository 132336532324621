import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormikContext } from "formik";
import { Button, Descriptions, Empty, List, QRCode, Radio, Spin } from "antd";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import { useCompaniesData } from "../../../../../Hooks/useCompaniesData";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}
function CompanySearchContent({ setCurrentStepper }: propsT) {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const { isLoading: isCompaniesDataLoading, companies } = useCompaniesData();
  const [rneSearch, setRneSearch] = useState<any | null>(null);
  const [type, setType] = useState<string>("pm");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const [isCompanyExists, setCompanyExists] = useState<boolean>(false);

  const nextStepHandler = () => {
    try {
      if (rneSearch) {
        setBtnIsLoading(true);

        const rsLatin =
          rneSearch.categorie === "ASSOCIATION"
            ? rneSearch?.nomAssociationFr?.trim()
            : rneSearch?.denominationLatin?.trim() ||
              `${rneSearch?.nomFr?.trim() || ""} ${
                rneSearch?.prenomFr?.trim() || ""
              }`;
        const rsArabic =
          rneSearch.categorie === "ASSOCIATION"
            ? rneSearch?.nomAssociationAr?.trim()
            : rneSearch?.denominationAr?.trim() ||
              `${rneSearch?.prenomAr?.trim() || ""} ${
                rneSearch?.nomAr?.trim() || ""
              }`;

        const company = {
          companyType: type.toUpperCase(),
          rsLatin,
          rsArabic,
          email: "",
          dateOfEst: null,
          activity: rneSearch.categorie,
          mainAddress: "",
          secondAddress: "",
          isActive: true,
          capital: "",
          VAT: rneSearch.identifiantUnique,
          rneID: rneSearch.numRegistre,
        };

        setFieldValue("company", company).then((r) =>
          setCurrentStepper((prev) => {
            return prev + 1;
          }),
        );
      }
    } catch (_) {
      setBtnIsLoading(false);
    }
  };

  const searchCompanyHandler = async (mf: string) => {
    try {
      if (mf === "") {
        setCompanyExists(false);
        setRneSearch(null);
        return;
      }

      if (
        companies.some((company) => {
          return company.VAT === mf;
        })
      ) {
        const existCompany = companies.filter((company) => {
          return company.VAT === mf;
        });
        setCompanyExists(true);
        setRneSearch(existCompany[0]);
        return;
      }
      setIsLoading(true);
      setRneSearch(null);
      const rneResult = await Axios.get(
        `/platform/rne/lookup?idUnique=${mf}&type=${type}`,
      ).then((res) => res.data);
      if (rneResult?.message?.count === 0) {
        setRneSearch([]);
      } else {
        setRneSearch(rneResult?.message?.registres[0]);
      }
      setIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col items-center  ">
      <div className="my-8">
        <Radio.Group
          defaultValue={type}
          buttonStyle="outline"
          onChange={(value) => {
            setType(value.target.value);
          }}
        >
          <Radio.Button value="pm">{t("pm")}</Radio.Button>
          <Radio.Button value="pp">{t("pp")}</Radio.Button>
        </Radio.Group>
      </div>
      <div className="w-full md:w-1/2  flex flex-col md:flex-row items-center justify-evenly mb-8">
        <Search
          placeholder={`${t("matricule_fiscal")} (0000000X)`}
          allowClear
          disabled={isCompaniesDataLoading}
          onSearch={(mf) => searchCompanyHandler(mf)}
          style={{ width: 304 }}
        />
      </div>

      {isLoading || isCompaniesDataLoading ? <Spin className="my-16" /> : null}

      {Array.isArray(rneSearch) && !rneSearch.length ? (
        <Empty description={t("no_data")} />
      ) : null}

      {rneSearch && !isCompanyExists && (
        <Descriptions bordered rootClassName="!mb-6" size="small" column={2}>
          {Object.keys(rneSearch).map((key, index) => {
            return rneSearch[key] ? (
              <Descriptions.Item label={t(key)} key={index}>
                {rneSearch[key]}
              </Descriptions.Item>
            ) : null;
          })}
        </Descriptions>
      )}

      {isCompanyExists && (
        <>
          <p className="text-red-800 mb-5 font-bold"> {t("company_exists")}</p>
          <List
            bordered
            size="default"
            rootClassName="w-full md:w-3/4"
            header={t("company_informations")}
          >
            <List.Item>
              <List.Item.Meta
                avatar={
                  <QRCode
                    value={rneSearch.companyID || "-"}
                    size={80}
                    bordered={false}
                  />
                }
                title={
                  <span>
                    {t("matricule_fiscal")}: {rneSearch.VAT}
                  </span>
                }
                description={
                  <div className="w-full flex flex-col gap-2">
                    <span>
                      {t("nomCommercialFr")}: {rneSearch.rsLatin}
                    </span>
                    {rneSearch.rsArabic && (
                      <span>
                        {t("nomCommercialAr")}: {rneSearch.rsArabic}
                      </span>
                    )}
                    {rneSearch.rsLatin && (
                      <span>
                        {t("typeRegistre")}:{" "}
                        {rneSearch.companyType === "PP" ? t("pp") : t("pm")}
                      </span>
                    )}
                  </div>
                }
              />
            </List.Item>
          </List>
        </>
      )}

      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4"
        disabled={!rneSearch || Array.isArray(rneSearch) || isCompanyExists}
        onClick={nextStepHandler}
        loading={isBtnLoading}
      >
        {t("next").toUpperCase()}
      </Button>
    </div>
  );
}

export default CompanySearchContent;
