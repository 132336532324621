import React from "react";
import { Drivers } from "../../../../../Types";
import { Card, Descriptions, Image, Tag } from "antd";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Meta from "antd/es/card/Meta";
import { fileUrlHandler } from "../../../../../Utils";
import { useMediaQuery } from "@mui/material";

export function SelectedDriverDescription({
  selectedDriver,
  isFirst,
  showFiles = false,
  showContent = true,
}: {
  selectedDriver: Drivers;
  isFirst: boolean;
  showFiles?: boolean;
  showContent?: boolean;
}) {
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full md:w-2/3 gap-6 flex-col">
      {showFiles && !showContent && (
        <>
          {isFirst ? (
            <Tag color="#f50"> {t("FIRST_DRIVER").toUpperCase()}</Tag>
          ) : (
            <Tag color="#108ee9"> {t("SECOND_DRIVER").toUpperCase()}</Tag>
          )}
        </>
      )}

      {showContent && (
        <Descriptions
          bordered
          rootClassName="!mb-6 "
          size="small"
          column={2}
          layout={isSmallDevice ? "vertical" : "horizontal"}
        >
          <Descriptions.Item span={2}>
            {isFirst ? (
              <Tag color="#f50"> {t("FIRST_DRIVER").toUpperCase()}</Tag>
            ) : (
              <Tag color="#108ee9"> {t("SECOND_DRIVER").toUpperCase()}</Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t("fullName")} span={2}>
            {selectedDriver?.fullName?.toUpperCase()}
          </Descriptions.Item>
          <Descriptions.Item label={t("dateOfBirth")}>
            {moment(selectedDriver?.dateOfBirth).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={t("idType")}>
            {t(selectedDriver?.idType || "")}
          </Descriptions.Item>
          <Descriptions.Item label={t("idNumber")}>
            {selectedDriver?.idNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t("deliveredDate")}>
            {moment(selectedDriver?.idIssueDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={t("licenceNumber")}>
            {selectedDriver?.licenceNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t("deliveredDate")}>
            {moment(selectedDriver?.licenceIssueDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={t("phone")}>
            {selectedDriver?.phone}
          </Descriptions.Item>
          <Descriptions.Item label={t("auth_email")}>
            {selectedDriver?.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("address")}>
            {selectedDriver?.address}
          </Descriptions.Item>
          <Descriptions.Item label={t("city")}>
            {selectedDriver?.city}
          </Descriptions.Item>
        </Descriptions>
      )}
      {showFiles && (
        <>
          <div className="flex flex-col md:flex-row justify-evenly items-center mb-6 gap-4">
            <Card
              style={{ width: 300 }}
              cover={
                <Image.PreviewGroup>
                  <Image
                    crossOrigin="anonymous"
                    src={fileUrlHandler(selectedDriver.idRecto.name)}
                  />
                </Image.PreviewGroup>
              }
            >
              <Meta title={t("idCard")} description={t("copie_recto")} />
            </Card>
            <Card
              style={{ width: 300 }}
              cover={
                <Image.PreviewGroup>
                  <Image
                    crossOrigin="anonymous"
                    src={fileUrlHandler(selectedDriver.idVerso.name)}
                  />
                </Image.PreviewGroup>
              }
            >
              <Meta title={t("idCard")} description={t("copie_verso")} />
            </Card>
          </div>
          <div className="flex flex-col md:flex-row justify-evenly items-center  gap-4">
            <Card
              style={{ width: 300 }}
              cover={
                <Image.PreviewGroup>
                  <Image
                    crossOrigin="anonymous"
                    src={fileUrlHandler(selectedDriver.licenceRecto.name)}
                  />
                </Image.PreviewGroup>
              }
            >
              <Meta title={t("licenceCard")} description={t("copie_recto")} />
            </Card>
            <Card
              style={{ width: 300 }}
              cover={
                <Image.PreviewGroup>
                  <Image
                    crossOrigin="anonymous"
                    src={fileUrlHandler(selectedDriver.licenceVerso.name)}
                  />
                </Image.PreviewGroup>
              }
            >
              <Meta title={t("licenceCard")} description={t("copie_verso")} />
            </Card>
          </div>
        </>
      )}
    </div>
  );
}
