import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormikContext } from "formik";
import { Button, DatePicker, Form, Input, Radio, Switch } from "antd";
import { FormValues } from "./index";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

function CompanyInfoContent({ setCurrentStepper }: propsT) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const [isInitPoints, setIsInitPoints] = useState<boolean>(false);

  dayjs.extend(customParseFormat);
  const createCompanyHandler = async () => {
    try {
      if (values.addedCompany?.VAT === values.company.VAT) {
        return setCurrentStepper((prev) => prev + 1);
      }
      setBtnIsLoading(true);
      const addedCompany = await Axios.post("/platform/company", {
        company: {
          ...values.company,
          dateOfEst: moment(values.company.dateOfEst).toISOString(),
        },
      }).then((res) => res.data.message);

      await setFieldValue("addedCompany", addedCompany).then((_) => {
        setCurrentStepper((prev) => prev + 1);
      });
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setBtnIsLoading(false);
    }
  };

  const goBackHandler = () => {
    setFieldValue("addedCompany", null);
    setFieldValue("company", {
      rneID: null,
      companyType: null,
      rsLatin: null,
      rsArabic: null,
      email: null,
      dateOfEst: null,
      activity: null,
      mainAddress: null,
      secondAddress: null,
      isActive: null,
      capital: null,
      VAT: null,
    });
    setCurrentStepper((prev) => prev - 1);
  };

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 1000, width: "100%" }}
        initialValues={values.company}
        onValuesChange={(fieldKeyValue) => {
          const fieldKey = Object.keys(fieldKeyValue)[0];
          const fieldValue = fieldKeyValue[fieldKey];
          setFieldValue(`company.${fieldKey}`, fieldValue);
        }}
      >
        <Form.Item
          label={t("typeRegistre")}
          name="disabled"
          valuePropName={values.company.companyType as string}
        >
          <Radio.Group value={values.company.companyType} buttonStyle="outline">
            <Radio.Button
              value="PM"
              disabled={values.company.companyType === "PP"}
            >
              {t("pm")}
            </Radio.Button>
            <Radio.Button
              value="PP"
              disabled={values.company.companyType === "PM"}
            >
              {t("pp")}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={t("matricule_fiscal")} name="VAT">
          <Input value={values.company.VAT || ""} disabled />
        </Form.Item>

        <Form.Item label={t("nomCommercialFr")} name="rsLatin">
          <Input value={values.company.rsLatin || ""} />
        </Form.Item>
        <Form.Item label={t("nomCommercialAr")} name="rsArabic">
          <Input value={values.company.rsArabic || ""} />
        </Form.Item>
        <Form.Item label={t("auth_email")} name="email">
          <Input value={values.company.email || ""} />
        </Form.Item>

        <Form.Item label={t("activity")} name="activity">
          <Input value={values.company.activity || ""} />
        </Form.Item>

        <Form.Item label={t("capital")} name="capital">
          <Input value={values.company.capital || ""} />
        </Form.Item>

        <Form.Item label={t("dateOfEst")} name="dateOfEst">
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(_, date) => {
              setFieldValue(
                "dateOfEst",
                moment(date, "DD/MM/YYYY").toISOString(),
              );
            }}
          />
        </Form.Item>

        <Form.Item label={t("mainAddress")} name="mainAddress">
          <TextArea rows={2} />
        </Form.Item>

        <Form.Item label={t("secondAddress")} name="secondAddress">
          <TextArea rows={2} />
        </Form.Item>

        <Form.Item label={t("activeCompany")} name="isActive">
          <Switch />
        </Form.Item>

        <Form.Item label={t("activeCompany")} name="isActive">
          <Switch />
        </Form.Item>
      </Form>
      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="default"
          className="!h-9  !text-xs !px-12   "
          onClick={goBackHandler}
        >
          {t("back").toUpperCase()}
        </Button>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={createCompanyHandler}
          loading={isBtnLoading}
        >
          {t("create_company").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default CompanyInfoContent;
