import { useTranslation } from "react-i18next";
import {
  Badge,
  Button,
  Collapse,
  DatePicker,
  Empty,
  InputNumber,
  Spin,
  Tag,
} from "antd";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { Formik } from "formik";
import { Axios } from "../../../Config";
import { toast } from "react-toastify";
import { RentContracts } from "../../../Types";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { DashboardResultRenderer } from "./DashboardResultRenderer";
import i18n from "i18next";

interface initialValuesT {
  plateLeft: number | null;
  plateRight: number | null;
  searchDate: Date | string | null;
}
export const DashboardConsultationSearch = () => {
  const { t } = useTranslation();

  const [searchResult, setSearchResult] = useState<RentContracts[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { language } = i18n;
  const initialValues: initialValuesT = {
    plateLeft: null,
    plateRight: null,
    searchDate: null,
  };

  const navigate = useNavigate();

  const onSubmitHandler = async (values: initialValuesT) => {
    try {
      setIsLoading(true);
      setSearchResult(null);

      const params = {
        plate: `${values.plateLeft}/${values.plateRight}`,
        searchDate: dayjs(values.searchDate).toISOString().toString(),
      };

      const result = await Axios.get("/consultant/rent-on-search", {
        params,
      }).then((result) => result.data.message);

      setSearchResult(result);
      setIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setIsLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
      {({ submitForm, values, setFieldValue }) => {
        return (
          <>
            <div className=" flex flex-col w-full mt-7 md:mt-14  items-center p-4 md:p-0 min-w-[400px] md:min-w-full  ">
              <div className=" w-full md:w-4/5 flex justify-center md:justify-end">
                <Button
                  type="primary"
                  className="!h-9 !bg-[#333333] !hover:bg-[#585757] text-xs !md:text-xs !px-12 !text-white mb-4  "
                  onClick={() => {
                    navigate("/consultant/history");
                  }}
                >
                  {t("search_history").toUpperCase()}
                </Button>
              </div>
              <div className="w-full md:w-4/5 rounded-xl bg-[#FFFFFF]   overflow-hidden">
                <div className="w-full flex bg-[#E6E8EC] h-10 px-8 items-center text-[#6E6E72] !font-light">
                  <TuneIcon fontSize="small" />
                  <span className="ml-5 ">
                    {t("search_filter").toUpperCase()}
                  </span>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-between  p-8 gap-8">
                  <div className="flex flex-col justify-center md:justify-start">
                    <span className="mb-3 text-[#6E6E72] text-sm w-full text-center md:text-left">
                      {t("car_number").toUpperCase()}
                    </span>
                    <div className="w-full flex justify-evenly md:justify-center">
                      <InputNumber
                        name="plateLeft"
                        min={0}
                        maxLength={3}
                        max={999}
                        controls={false}
                        onChange={(value) => {
                          setSearchResult(null);
                          setFieldValue("plateLeft", value);
                        }}
                      />
                      <span className="text-[#6E6E72] mx-4">TU</span>
                      <InputNumber
                        name="plateRight"
                        min={0}
                        maxLength={4}
                        max={9999}
                        controls={false}
                        onChange={(value) => {
                          setSearchResult(null);
                          setFieldValue("plateRight", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="mb-3 text-[#6E6E72] text-sm  w-full text-center md:text-left">
                      {t("infraction_date").toUpperCase()}
                    </span>
                    <DatePicker
                      showToday
                      format="DD/MM/YYYY"
                      disabledDate={(day) => {
                        return Boolean(
                          moment(new Date()).diff(moment(day.toISOString())) <
                            0 ||
                            moment(new Date())
                              .subtract(2, "year")
                              .diff(moment(day.toISOString())) > 0,
                        );
                      }}
                      onChange={(_, date) => {
                        setFieldValue(
                          "searchDate",
                          moment(date, "DD/MM/YYYY").toISOString(),
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col justify-end">
                    <Button
                      className="!h-9  !text-xs  !px-12  "
                      disabled={
                        !Boolean(
                          values.searchDate &&
                            values.plateLeft &&
                            values.plateRight,
                        )
                      }
                      onClick={submitForm}
                    >
                      <SearchIcon fontSize="small" />{" "}
                      <span className="ml-5"> {t("search").toUpperCase()}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-4/5 mt-9 ">{isLoading && <Spin />}</div>
              {searchResult && (
                <>
                  <div className="w-full md:w-4/5 mb-4 flex gap-4 items-center">
                    {t("search_result")} :
                    <Badge count={searchResult.length} showZero />
                  </div>

                  {searchResult.length ? null : (
                    <Empty description={t("no_result_found")} />
                  )}

                  <Collapse
                    rootClassName="w-full md:w-4/5"
                    items={searchResult.map((result) => {
                      return {
                        key: result.rentContractID,
                        label: (
                          <div className="flex  flex-col gap-4">
                            <div className="flex w-full gap-4 flex-col md:flex-row">
                              {language === "ar"
                                ? result.company.rsArabic
                                : result.company.rsLatin}
                              <Tag color="default">
                                {t("VAT")} : {result.company.VAT}
                              </Tag>
                              {result.isRentWithDriver && (
                                <Tag color="cyan">{t("isRentWithDriver")}</Tag>
                              )}
                            </div>
                            <div className=" flex flex-col md:flex-row gap-4    ">
                              <Tag color="green">
                                {t("start_date")} :
                                {moment(result.startDate).format(
                                  "DD/MM/YYYY HH:MM",
                                )}
                              </Tag>
                              <Tag color="red">
                                {t("end_date")} :
                                {moment(result.endDate).format(
                                  "DD/MM/YYYY HH:MM",
                                )}
                              </Tag>
                            </div>
                          </div>
                        ),
                        children: <DashboardResultRenderer contract={result} />,
                      };
                    })}
                    defaultActiveKey={["1"]}
                  />
                </>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};
