import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import CopyToClipboard from "../../../../UI/CopyToClipboard";
import { SubscriptionPrices } from "../../../../../Types";
import { Tag } from "antd";

export const SubscriptionPricesTableColumns: (
  t: any,
  openModal?: any,
) => (
  | ColumnGroupType<SubscriptionPrices>
  | ColumnType<SubscriptionPrices>
)[] = (t) => [
  {
    title: t("ID"),
    dataIndex: "subscriptionPriceID",
    key: "subscriptionPriceID",
    render: (value) => {
      return (
        <div className="flex w-full">
          <span className="text-gray-700">{t("copy_id")}</span>
          <CopyToClipboard input={value} showInput={false} />
        </div>
      );
    },
  },
  {
    title: t("subscriptionName"),
    dataIndex: "subscriptionName",
    key: "subscriptionName",
    sorter: (a, b) =>
      a.subscriptionName
        ? a.subscriptionName.localeCompare(b.subscriptionName)
        : 0,

    render: (value) => {
      return (
        <Tag color="processing" rootClassName="uppercase font-bold text-lg">
          {value}
        </Tag>
      );
    },
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.subscriptionName
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("subscriptionDescription"),
    dataIndex: "description",
    key: "description",
    render: (value) => {
      return <CopyToClipboard input={value} showIcon={false} />;
    },
    sorter: (a, b) =>
      (a.description &&
        b.description &&
        a.description.localeCompare(b.description)) ||
      0,
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.description
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("price"),
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (value) => {
      return (
        <CopyToClipboard input={`${value} ${t("TND")}`} showIcon={false} />
      );
    },
    sorter: (a, b) => a.price - b.price,
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.price
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("subscriptionPeriod"),
    dataIndex: "subscriptionPeriod",
    key: "subscriptionPeriod",
    render: (value) => {
      return t(value);
    },
    sorter: (a, b) => a.subscriptionPeriod.localeCompare(b.subscriptionPeriod),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.subscriptionPeriod
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },

  {
    title: t("maxVehicle"),
    dataIndex: "maxVehicle",
    key: "maxVehicle",
    render: (value) => {
      return (
        <div className="text-orange-700 text-sm font-bold">{Number(value)}</div>
      );
    },
    sorter: (a, b) => a.maxVehicle - b.maxVehicle,
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.maxVehicle
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("isRenewable"),
    dataIndex: "isRenewable",
    render: (isActive?: boolean) =>
      isActive ? (
        <Tag color="success">{t("yes")}</Tag>
      ) : (
        <Tag color="error">{t("no")}</Tag>
      ),
  },
  {
    title: t("isDeleted"),
    dataIndex: "isDeleted",
    key: "isDeleted",
    render: (isActive?: boolean) =>
      isActive ? (
        <Tag color="success">{t("yes")}</Tag>
      ) : (
        <Tag color="error">{t("no")}</Tag>
      ),
  },

  {
    title: t("nbSubscriptions"),
    dataIndex: "Subscription",
    key: "Subscription",
    render: (value) => {
      return value.length;
    },
    sorter: (a, b) => a.Subscription.length - b.Subscription.length,
    sortDirections: ["descend", "ascend"],
  },
];
