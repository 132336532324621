import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { Button, List, Switch } from "antd";
import SecurityIcon from "@mui/icons-material/Security";
import { useTranslation } from "react-i18next";
import OtpCustomInput from "../../UI/OtpCustomInput";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { toast } from "react-toastify";
import { Axios } from "../../../Config";
import { AppDispatch } from "../../../Store/store";
import {
  changeOtpStatusAction,
  generateOtpAction,
  verifyOtpAction,
} from "../../../Store/actions";

function Settings2FaContant() {
  const [otpCode, setOtpCode] = useState<string>("");
  const user = useSelector((state: any) => state.authentication.user);
  const { t } = useTranslation();
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const changeOtpStatusHAndler = async () => {
    try {
      setIsSwitchLoading(true);
      const otpResponse = await Axios.put("/otp/change-status").then(
        (res) => res.data,
      );
      dispatch(changeOtpStatusAction(!user.isOtpEnabled));
      toast.success(t(otpResponse.message["RESPONSE_CODE"]));
      setIsSwitchLoading(false);
    } catch (e: any) {
      setIsSwitchLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const generateOtpQrCodeHandler = async () => {
    try {
      setIsBtnLoading(true);
      const otpResponse = await Axios.get("/otp/generate").then(
        (res) => res.data,
      );
      dispatch(generateOtpAction(otpResponse?.OTP));

      setIsBtnLoading(false);
    } catch (e: any) {
      setIsBtnLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const verifyOtpQrCodeHandler = async () => {
    try {
      setIsBtnLoading(true);
      const otpResponse = await Axios.post("/otp/verify", {
        code: Number(otpCode),
      }).then((res) => res.data);
      dispatch(verifyOtpAction());
      setOtpCode("");
      toast.success(t(otpResponse.message["RESPONSE_CODE"]));
      setIsBtnLoading(false);
    } catch (e: any) {
      setIsBtnLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="p-10 md:p-16 flex flex-col md:flex-row w-full h-fit md:h-full gap-5 justify-center items-center">
      <div className="w-full h-fit md:h-full md:w-1/2 max-w-[444px] max-h-[500px] flex flex-col md:justify-evenly">
        <p
          className="text-justify text-xs md:text-sm text-[#929292] mb-10 md:mb-0"
          style={{ fontFamily: "Urbanist" }}
        >
          <span className="font-medium">{t("otp_info_question")}</span>
          {t("otp_info_content")}
        </p>
        <List bordered size="default">
          <List.Item
            actions={[
              <Switch
                disabled={!user.isOtpVerified}
                checked={user.isOtpEnabled}
                onChange={changeOtpStatusHAndler}
                rootClassName="bg-black"
                loading={isSwitchLoading}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={<SecurityIcon fontSize="medium" />}
              title={user.name}
              description={
                user.isOtpEnabled ? t("otp_enabled") : t("otp_disabled")
              }
            />
          </List.Item>
        </List>
        <p
          className="text-justify text-xs md:text-sm text-[#929292] mt-10 md:mt-0"
          style={{ fontFamily: "Urbanist" }}
        >
          {t("otp_cta_text")}
        </p>
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center h-full">
        <div className="border max-w-[444px] max-h-[500px] border-gray-200 w-full md:min-h-full rounded-2xl flex flex-col justify-evenly items-center py-4 px-3 md:p-6">
          {user.isOtpVerified || user.otpAuthUrl ? (
            <>
              <QRCodeSVG value={user.otpAuthUrl} size={170} />
              <div className="  w-full mt-8 ">
                <OtpCustomInput
                  inputType="number"
                  value={otpCode as string | undefined}
                  onChange={(value) => setOtpCode(value)}
                  onPaste={(value) =>
                    setOtpCode(value.clipboardData.getData("string"))
                  }
                  numInputs={6}
                  inputClassName=" !w-8 !h-8 md:!w-12 md:!h-12"
                />
                <Button
                  type="primary"
                  className="w-full disabled:!text-white !h-10 !bg-[#1D222C] !hover:bg-[#2A2F38] mt-8"
                  loading={isBtnLoading}
                  disabled={!otpCode || otpCode?.length < 6}
                  onClick={verifyOtpQrCodeHandler}
                >
                  {t("otp_verify_btn")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <FingerprintIcon sx={{ fontSize: "170px" }} />
              <Button
                type="primary"
                className="w-full !h-10 !bg-[#1D222C] !hover:bg-[#2A2F38] mt-8"
                onClick={generateOtpQrCodeHandler}
                loading={isBtnLoading}
              >
                {t("otp_generate_btn")}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings2FaContant;
