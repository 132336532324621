import React from "react";
import "./Assets/styles/index.scss";
import { AppRoutes, AuthRoutes, GeneralRoutes } from "./Routes";
import { ToastContainer } from "react-toastify";
import { SettingsRoutes } from "./Routes/SettingsRoutes";
import "moment/locale/fr";
import "moment/locale/ar-tn";
import { ConsultantRoutes } from "./Routes/ConsultantRoutes";
import { useSelector } from "react-redux";
import { RootReducer } from "./Store/reducers";
import { Users } from "./Types";
import { PlatformManagementRoutes } from "./Routes/PlatformManagementRoutes";
import { CompanyManagementRoutes } from "./Routes/CompanyManagementRoutes";

function App(): JSX.Element {
  const user = useSelector(
    (state: RootReducer) => state?.authentication?.user,
  ) as Users;

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <AuthRoutes />
      <GeneralRoutes />
      <AppRoutes />
      <SettingsRoutes />

      {user?.accountType === "CONSULTANT" && user?.isConsultantAdmin && (
        <ConsultantRoutes />
      )}
      {user?.accountType === "PLATFORM" && <PlatformManagementRoutes />}
      {user?.accountType === "ENTREPRISE" && <CompanyManagementRoutes />}
    </>
  );
}

export default App;
