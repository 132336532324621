import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { t } from "i18next";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uniqueObjectsByProperty<T>(arr: T[], property: keyof T): T[] {
  const uniqueValues: T[] = [];
  const seenValues: Set<any> = new Set();

  for (const item of arr) {
    const value = item[property];
    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueValues.push(item);
    }
  }

  return uniqueValues;
}

export const enumsToSelectOptions = (enums: any) => {
  return (
    (enums &&
      enums.map((enu: string[]) => {
        return { value: enu, label: t(enu) };
      })) ||
    []
  );
};

export const fileUrlHandler = (target: string | null) => {
  return (
    (target &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
        "/media/" +
        target) ||
    ""
  );
};
