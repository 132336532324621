import "../CompanyComponents/DashboardContent/calander.css";
import { Tabs } from "antd";
import { CalculatorOutlined, CalendarOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CompanyDashboardCalender } from "../CompanyComponents/DashboardContent/CompanyDashboardCalender";
import CompanyDashboardCalculator from "../CompanyComponents/DashboardContent/CompanyDashboardCalculator";
import CompanyStats from "../CompanyComponents/DashboardContent/CompanyStats";

export const DashboardCompany = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  /*  {
    Component: CompanyDashboardAvailability,
        Icon: CarOutlined,
      label: t("rent_availability"),
  },*/
  const tabs = [
    {
      Component: CompanyDashboardCalculator,
      Icon: CalculatorOutlined,
      label: t("rent_calculator"),
    },
    {
      Component: CompanyDashboardCalender,
      Icon: CalendarOutlined,
      label: t("rent_calender"),
    },
  ];
  return (
    <div className="  w-full    h-fit  rounded-2xl p-4 md:p-8">
      <CompanyStats />
      <Tabs
        direction={language === "ar" ? "rtl" : "ltr"}
        defaultActiveKey="0"
        items={tabs.map(({ Icon, label, Component }, i) => {
          return {
            key: String(i),
            label: label,
            children: <Component />,
            icon: <Icon />,
          };
        })}
      />
    </div>
  );
};
