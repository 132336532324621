import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { RentContracts } from "../Types";

export const useCompanyRentContractsData = (
  refreshInterval: number | undefined = 10000,
  trigger: boolean = true,
) => {
  const { t } = useTranslation();

  const [contracts, setContracts] = useState<RentContracts[] | []>([]);

  const { data, isLoading, error, mutate } = useSwiftSWR(
    trigger ? "/rent/contracts" : null,
    {
      refreshInterval,
    },
  );

  useEffect(() => {
    const handleSearchData = () => {
      if (!isLoading && data) {
        setContracts(data.message);
      }
    };

    const handleSearchError = () => {
      if (error) {
        toast.error(
          t(
            error?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleSearchData();
    handleSearchError();

    // eslint-disable-next-line
  }, [data, isLoading, error]);

  return { contracts, isLoading, error, mutate };
};
