import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { changePasswordValidation } from "../../../Validation/changePasswordValidation";
import { ChangePasswordPropsT } from "../../../Types";
import { ChangePasswordService } from "../../../Services/AuthService/ChangePasswordService";
import { toast } from "react-toastify";

function ForgetPasswordContant() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialFormState: ChangePasswordPropsT = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const changePasswordHandler = async (values: ChangePasswordPropsT) => {
    try {
      setIsLoading(true);
      const changePasswordRes = await ChangePasswordService(values).then(
        (res) => res.data,
      );
      toast.success(t(changePasswordRes.message["RESPONSE_CODE"]));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  return (
    <div className="h-fit md:h-full flex flex-col justify-center items-center">
      <div className=" max-w-[500px]  w-full md:min-h-full rounded-2xl flex flex-col justify-center items-center p-8">
        <Formik
          initialValues={initialFormState}
          validationSchema={changePasswordValidation}
          onSubmit={changePasswordHandler}
          isInitialValid={false}
          validateOnChange={true}
        >
          {({
            errors,
            isValid,
            submitForm,
            handleChange,
            handleBlur,
            values,
          }) => {
            return (
              <>
                <Input.Password
                  name="oldPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.oldPassword}
                  size="large"
                  placeholder={t("old_password")}
                  status={errors.oldPassword ? "error" : ""}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone rev={undefined} />
                    ) : (
                      <EyeInvisibleOutlined rev={undefined} />
                    )
                  }
                />
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.oldPassword && t(errors.oldPassword)}
                </h3>
                <Input.Password
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  size="large"
                  placeholder={t("new_password")}
                  status={errors.newPassword ? "error" : ""}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone rev={undefined} />
                    ) : (
                      <EyeInvisibleOutlined rev={undefined} />
                    )
                  }
                />
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.newPassword && t(errors.newPassword)}
                </h3>
                <Input.Password
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  size="large"
                  placeholder={t("confirm_password")}
                  status={errors.confirmPassword ? "error" : ""}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone rev={undefined} />
                    ) : (
                      <EyeInvisibleOutlined rev={undefined} />
                    )
                  }
                />
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.confirmPassword && t(errors.confirmPassword)}
                </h3>
                <Button
                  loading={isLoading}
                  type="primary"
                  className="w-full mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  {t("change-password")}
                </Button>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ForgetPasswordContant;
