import React from "react";
import { useFormikContext } from "formik";
import { InvoiceInitialValues } from "./AddPlatformInvoiceContent";
import { PlatformInvoicePrintable } from "../../../../UI/Printables/PlatformInvoicePrintable";

function DisplayAddedInvoice() {
  const { values } = useFormikContext<InvoiceInitialValues>();

  if (!values.savedInvoice) {
    return null;
  }
  return <PlatformInvoicePrintable PlatformInvoice={values.savedInvoice} />;
}

export default DisplayAddedInvoice;
