import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { useFleetData } from "../../../../../Hooks";
import { Button, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import { Fleet } from "../../../../../Types";
import { SelectedCarDescription } from "./SelectedCarDescription";

function SelectCarToRent({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const { t } = useTranslation();
  const { fleet, isLoading } = useFleetData();

  const [selectedCar, setSelectedCar] = useState<Fleet | null>(null);

  const fleetToOptions = fleet
    ? fleet.map((car) => {
        return {
          label: `${car.manufacturer.replaceAll("_", " ")} ${
            car.modal
          }   |   ${car.registrationNumber.replace("/", ` ${t("TU")} `)}  `,
          value: car.fleetID,
        };
      })
    : [];

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <FormItem
        label={t("car_number")}
        name="idType"
        className="w-full md:w-2/3"
      >
        <Select
          allowClear
          key={"fleetID"}
          placeholder={t("car_number")}
          optionFilterProp="children"
          value={values.fleetID || ""}
          style={{ width: "100%" }}
          options={fleetToOptions}
          disabled={isLoading}
          loading={isLoading}
          onChange={(value) => {
            const selectedCar =
              fleet?.filter((car) => car.fleetID === value)[0] || null;
            setSelectedCar(selectedCar);
          }}
        />
      </FormItem>

      {selectedCar ? (
        <SelectedCarDescription selectedCar={selectedCar} />
      ) : null}
      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setFieldValue("fleetID", selectedCar?.fleetID).then(() => {
              setCurrentStepper((prevState) => prevState + 1);
            });
          }}
          disabled={!selectedCar}
        >
          {t("select_car").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default SelectCarToRent;
