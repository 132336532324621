import React, { FC, PropsWithChildren, useState } from "react";
import { NavBar } from "../Components";
import { SideBar } from "../Components/Features/SideBar/SideBar";
import { useTranslation } from "react-i18next";
import { FloatButton } from "antd";
import {
  FileAddOutlined,
  ThunderboltOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export const AppLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector((state: any) => state.authentication.user);

  const onChange = () => {
    setOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();

  return (
    <main
      className={`w-screen overflow-x-hidden h-screen max-h-screen flex flex-nowrap  relative box-border ${
        language === "ar" ? "right-to-left" : ""
      }`}
    >
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />

      <div className="w-full max-w-full box-border h-screen min-h-screen max-h-screen  bg-[#F5F5F5] flex flex-col relative">
        <NavBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="w-full max-w-full h-fit  flex flex-col overflow-x-hidden overflow-auto relative ">
          {children}

          {user.accountType === "ENTREPRISE" ? (
            <FloatButton.Group
              open={open}
              trigger="click"
              style={{ right: 24 }}
              onClick={onChange}
              tooltip={t("actions")}
              icon={<ThunderboltOutlined />}
            >
              <FloatButton
                icon={<FileAddOutlined />}
                tooltip={t("add_newRent")}
                onClick={() => navigate("/rent_management/add_newRent")}
              />
              <FloatButton
                icon={<UserAddOutlined />}
                tooltip={t("add_client")}
                onClick={() => navigate("/clients_management/add_client")}
              />
            </FloatButton.Group>
          ) : null}
        </div>
      </div>
    </main>
  );
};
