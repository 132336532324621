import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import GroupsIcon from "@mui/icons-material/Groups";
import { useCompaniesStatsData } from "../../../../Hooks/useCompaniesStatsData";
import SkeletonButton from "antd/es/skeleton/Button";
import { useMediaQuery } from "@mui/material";

function CompanyStats() {
  const { t } = useTranslation();

  const { companiesStats, isLoading: isCompaniesStatsLoading } =
    useCompaniesStatsData(2000);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="max-w-[85rem]  mb-8">
      <div className="grid sm:grid-cols-4 border-y border-gray-200 dark:border-neutral-800">
        <div className="  p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800 ">
          <div
            className={
              isSmallDevice ? "flex justify-center items-center gap-4" : ""
            }
          >
            <GroupsIcon />
            <div className="mt-3">
              <div className="flex items-center gap-x-2">
                <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                  {t("clients")}
                </p>
              </div>
              <div className="mt-1 lg:flex lg:justify-between lg:items-center">
                <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                  {typeof companiesStats?.clientsCount === "number" ||
                  companiesStats?.clientsCount ? (
                    String(companiesStats?.clientsCount || 0)
                  ) : (
                    <SkeletonButton active size="small" shape="square" />
                  )}
                </h3>
                <Link
                  className="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-gray-200/70 hover:bg-gray-200 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-200"
                  to="/clients_management/clients_listing"
                >
                  <span className="inline-block text-xs font-semibold uppercase">
                    {t("voire")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div
            className={
              isSmallDevice ? "flex justify-center items-center gap-4" : ""
            }
          >
            <DirectionsCarIcon />
            <div className="mt-3">
              <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                {t("fleet")}
              </p>
              <div className="mt-1 lg:flex lg:justify-between lg:items-center">
                <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                  {typeof companiesStats?.fleetCount === "number" ||
                  companiesStats?.fleetCount ? (
                    String(companiesStats?.fleetCount || 0)
                  ) : (
                    <SkeletonButton active size="small" shape="square" />
                  )}
                </h3>
                <Link
                  className="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-gray-200/70 hover:bg-gray-200 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-200"
                  to="/fleet_management/fleet_listing"
                >
                  <span className="inline-block text-xs font-semibold uppercase">
                    {t("voire")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div
            className={
              isSmallDevice ? "flex justify-center items-center gap-4" : ""
            }
          >
            <ReceiptLongIcon />
            <div className="mt-3">
              <div className="flex items-center gap-x-2">
                <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                  {t("rent_contracts")}
                </p>
              </div>
              <div className="mt-1 lg:flex lg:justify-between lg:items-center">
                <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                  {typeof companiesStats?.rentCount === "number" ||
                  companiesStats?.rentCount ? (
                    String(companiesStats?.rentCount || 0)
                  ) : (
                    <SkeletonButton active size="small" shape="square" />
                  )}
                </h3>
                <Link
                  className="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-gray-200/70 hover:bg-gray-200 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-200"
                  to="/rent_management/rent_listing"
                >
                  <span className="inline-block text-xs font-semibold uppercase">
                    {t("voire")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div
            className={
              isSmallDevice ? "flex justify-center items-center gap-4" : ""
            }
          >
            <RequestPageIcon />
            <div className="mt-3">
              <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                {t("bills")}
              </p>
              <div className="mt-1 lg:flex lg:justify-between lg:items-center">
                <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                  {typeof companiesStats?.billsCount === "number" ||
                  companiesStats?.billsCount ? (
                    String(companiesStats?.billsCount || 0)
                  ) : (
                    <SkeletonButton active size="small" shape="square" />
                  )}
                </h3>
                <Link
                  className="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-gray-200/70 hover:bg-gray-200 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-200"
                  to="/bills_management/bills_listing"
                >
                  <span className="inline-block text-xs font-semibold uppercase">
                    {t("voire")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyStats;
