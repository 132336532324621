import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Users } from "../../../Types";
import { useMemo } from "react";
import CompanyDashboardGreeting from "../CompanyComponents/DashboardContent/CompanyDashboardGreeting";

export const DashboardGreating = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const user = useSelector((state: any) => state.authentication.user) as Users;

  const getGreeting = useMemo(() => {
    const currentHour = moment().hours();

    if (currentHour >= 5 && currentHour < 12) {
      return t("good_morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("good_afternoon");
    } else {
      return t("good_evening");
    }
  }, [language]);

  if (user.accountType === "ENTREPRISE") {
    return <CompanyDashboardGreeting />;
  }

  return (
    <div className="w-full flex flex-col justify-cente items-center">
      <h3
        className="font-normal text-sm md:text-lg text-[#1E232C] text-center"
        style={{ fontFamily: "Inter" }}
      >
        {moment()
          .format("dddd, MMMM Do")
          .replace(/\b\w/g, (l) => l.toUpperCase())}
      </h3>
      <h3
        className="font-semibold text-xl md:text-3xl text-[#1E232C] mt-2 text-center "
        style={{ fontFamily: "Urbanist" }}
      >
        {getGreeting}, {user?.name}
      </h3>
    </div>
  );
};
