import React, { useState } from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useSubscriptionPriceData } from "../../../../../Hooks/useSubscriptionPriceData";
import { SubscriptionPricesTableColumns } from "./SubscriptionPricesTableColumns";
import { AddSubscriptionPriceModal } from "./AddSubscriptionPriceModal";

export function SubscriptionPricesListingContent() {
  const { t } = useTranslation();
  const {
    isLoading: isCompaniesDataLoading,
    subscriptionPrices,
    mutate,
  } = useSubscriptionPriceData();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const columns = SubscriptionPricesTableColumns(t);
  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {isOpen && (
        <AddSubscriptionPriceModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          mutate={mutate}
        />
      )}
      <div className="w-full flex gap-4 flex-col md:flex-row mb-8 justify-end">
        <Button type="default" className="!h-9  !text-xs !px-12   ">
          {t("locasafe_dashboard").toUpperCase()}
        </Button>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("create_new_subscription_price").toUpperCase()}
        </Button>
      </div>
      <Table
        loading={isCompaniesDataLoading}
        columns={columns}
        dataSource={subscriptionPrices}
        rowKey="subscriptionPriceID"
        size="middle"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={true}
        pagination={{
          pageSize: 5,
        }}
      />
    </div>
  );
}
