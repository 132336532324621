import React from "react";
import { Menu } from "antd";
import { getItem } from "../SideBar/SideBarMenu";
import {
  SafetyCertificateOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function NavBarUserMenu({ MenuKey = "NavBarUserMenu" }: { MenuKey?: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  return (
    <Menu
      key={MenuKey}
      theme="light"
      mode="vertical"
      style={{ border: "none" }}
      items={[
        getItem(t("user_profile"), "/settings/profile", <UserOutlined />),

        getItem(
          t("change-password"),
          "/settings/change-password",
          <SafetyCertificateOutlined />,
        ),

        getItem(
          t("activity-history"),
          "/settings/activity-history",
          <SolutionOutlined />,
        ),
      ]}
      multiple={false}
      selectable={false}
      defaultSelectedKeys={[currentPath]}
      onClick={(e) => {
        navigate(e.key);
      }}
    />
  );
}
// getItem(t("2FA"), "/settings/2FA", <QrcodeOutlined />),

export default NavBarUserMenu;
